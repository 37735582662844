const fr = {
    // pages helpers text
    BRANCH_LIST_PAGE_HELPER_TEXT: '',
    BANK_ACCOUNT_LIST_PAGE_HELPER_TEXT: '',
    BRANCH_CASHBOX_LIST_PAGE_HELPER_TEXT: '',
    CUSTOMER_LIST_PAGE_HELPER_TEXT: '',
    EXPENSE_LIST_PAGE_HELPER_TEXT: '',
    INVOICE_LIST_PAGE_HELPER_TEXT: '',
    PERMISSION_GROUP_LIST_PAGE_HELPER_TEXT: '',
    PRICING_GROUP_LIST_PAGE_HELPER_TEXT: '',
    RENTABLE_LIST_PAGE_HELPER_TEXT: '',
    RESERVATION_LIST_PAGE_HELPER_TEXT: '',
    SEASON_LIST_PAGE_HELPER_TEXT: '',
    AGENCY_LIST_PAGE_HELPER_TEXT: '',
    CLASS_LIST_PAGE_HELPER_TEXT: '',
    EXPENSE_CONFIG_LIST_PAGE_HELPER_TEXT: '',
    USER_LIST_PAGE_HELPER_TEXT: '',
    REJECTED_RESERVATIONS_LIST_PAGE_HELPER_TEXT: '',
    RENTABLE_TYPE_LIST_PAGE_HELPER_TEXT: '',
    RENTABLE_EXTRA_TYPE_LIST_PAGE_HELPER_TEXT: '',
    BRAND_LIST_PAGE_HELPER_TEXT: '',
    MODEL_LIST_PAGE_HELPER_TEXT: '',
    AFFILIATION_LIST_PAGE_HELPER_TEXT: '',
    // configs categories
    GENERAL_SETTINGS: 'Paramètres généraux',
    RESERVATIONS: 'Réservations',
    FINANCIALS: 'Financiers',
    RESERVATION: 'Réservation',
    FINANCIAL: 'Financier',
    OTHER: 'Autre',
    // configs fields
    VATID: 'ID TVA',
    TAXID: 'ID FISCALE',
    PHONENUMBER: 'Numéro de téléphone',
    INVOICENUMBER: 'Numéro de facture',
    CONTRACTNUMBER: 'Numéro du contrat',
    TRANSFERKMCOST: 'Transfert kilométrique coût',
    ALLOWADDITIONALDRIVER: 'Autoriser un conducteur supplémentaire',
    EXTRADRIVERFEE: 'Taxe de conduite supplémentaire',
    FUELPOLICY: 'Politique de carburant',
    ALLOWVAT: 'Permettre la TVA',
    VAT: 'TVA',
    REQUIRETRANSTERCOST: 'Coût de transfert requis',
    TRANSFERBASECOST: 'Coût de base du transfert',
    TRANSTERKMCOST: 'Coût du kilomètre de transfert',
    MAXDAILYKM: 'Kilométrage quotidien maximum',
    EXCEEDEDMAXDAILYKMSURCHARGE: 'Supplément kilométrique quotidien excédentaire',
    YOUNGRENTABLESURCHARGEPERCENTAGE: 'Pourcentage de supplément pour jeune véhicule',
    YOUNGRENTABLESURCHARGE: 'Supplément jeune véhicule',
    YOUNGVEHICLEMONTHLIMIT: 'Limite mensuelle du véhicule jeune',
    REQUIREDDEPOSIT: 'Dépôt requis',
    DEFERREDPAYMENTSURCHARGE: 'Supplément pour paiement différé',
    NBRHOURSFORNEXTRESERVATION: 'Nombre d’heures avant la prochaine réservation',
    REQUIRETRANSFERCOST: 'Coût de transfert requis',
    YOUNGVEHICLESURCHARGEPERCENTAGE: 'Pourcentage de supplément pour jeunes véhicules',
    YOUNGVEHICLESURCHARGE: 'Supplément pour jeune véhicule',
    DEPOSITAMOUNT: 'Deposit amount',
    DENYNOTPAIDRESERVATION: 'Refuser la réservation non payée',
    REQUIREDMANUALPRICE: 'Prix manuel requis',
    MANUALPRICERESERVATIONPURPOSE: 'Objectif de la réservation manuelle des prix',
    MANUAL_PRICE: 'Prix Manuel',
    UNPAID_PICKUP: 'Ramassage Non Payé',
    CAR_DAMAGE_CONTINUE: 'Dommages de location (Continuer)',
    CAR_DAMAGE_CHANGE: 'Dommages de location (Changement)',
    CAR_DAMAGE_RETURN: 'Dommages de location (Retour)',
    CAR_DAMAGE_NON_FUNCTIONAL: 'Dommages de location (Non Fonctionnelle)',
    CAR_DAMAGE: 'Dommages de location',
    // helper field text
    'This is the main branch': 'C’est la branche principale',
    //
    // 'rentable extra': 'Additional services',
    // 'Rentable Extra': 'Additional services',
    // 'rentable extra type': 'Additional services type',
    // 'Rentable Extra Type': 'Additional services type',
    'rentable types': 'Types de services louables',
    'rentable extra types': 'Types de services supplémentaires',
    'Create A New Pricing Group': 'Créer un nouveau groupe de tarification',
    'Edit Class': 'Modifier la classe',
    'Create A New Season': 'Créer une nouvelle saison',
    'Create A New Expense': 'Créer une nouvelle dépense',
    'New User': 'Nouvel utilisateur',
    'Edit Season': 'Modifier la saison',
    'Edit User': 'Modifier l’utilisateur',
    'expense config': 'configuration des dépenses',
    'expense configs': 'configs de dépenses',
    'New Model': 'Nouveau modèle',
    'New model': 'Nouveau modèle',
    'Create A New Model': 'Créer un nouveau modèle',
    'Edit Model': 'Modifier le modèle',
    'Model Details': 'Détails du modèle',
    Brands: 'Marques',
    'Edit Brand': 'Modifier la marque',
    Icon: 'Icône',
    'Brand deleted successfully': 'Marque supprimée avec succès',
    'brands deleted successfully': 'Marque(s) supprimée(s) avec succès',
    Inactive: 'Inactif',
    'New brand': 'Nouvelle marque',
    'The customer has not paid the required deposit': 'Le client n’a pas payé l’acompte requis',
    'Retrieve the title of the chosen icon from': 'Récupérer le titre de l’icône choisie sur',
    Priority: 'Priorité',
    priority: 'priorité',
    Code: 'Code',
    code: 'code',
    'amount must be different from 0': 'le montant doit être différent de 0',
    'Rentable type': 'Type location',
    'rentable type': 'type location',
    'Icon Preview': 'Aperçu des icônes',
    'New rentable type': 'Nouveau type de location',
    'Search by name or code...': 'Recherche par nom ou code...',
    'RentableType created successfully': 'Type de location créé avec succès',
    'RentableType updated successfully': 'Type de location mis à jour avec succès',
    'RentableType deleted successfully': 'Type de location supprimé avec succès',
    'RentableTypes deleted successfully': 'Type(s) de location supprimés avec succès',
    'Rentable extra type': 'Type supplémentaire louable',
    'Rentable Types': 'Types de location',
    'Rentable types': 'Types de location',
    'rentableTypes is required': 'Types de location requis',
    'New rentable extra type': 'Nouveau type supplémentaire de location',
    'Rentable extra types': 'Types supplémentaires louables',
    'Rentable extra type details': 'Renseignements supplémentaires sur le type loué',
    'Rentable type details': 'Renseignements sur le type de location',
    'Create A New Rentable Type': 'Créer un nouveau type location',
    'Create a new rentable extra type': 'Créer un nouveau type supplémentaire de location',
    'Edit rentable extra type': 'Modifier le type supplémentaire de location',
    'Edit rentable type': 'Modifier le type de location',
    of: 'de',
    'Would you like to proceed with the booking': 'Voulez-vous procéder à la réservation',
    'To continue creating invoices, please configure the': 'Pour continuer à créer des factures, veuillez configurer le',
    'config by accessing the': 'config en accédant au',
    'Edit Agency': 'mise à jour l`agence',
    'option through this link': 'option via ce lien',
    'To continue creating invoices, the': 'Pour continuer à créer des factures, le',
    'agency config must be set up. Since you lack permission, please inform your admin.': 'La configuration de l’agence doit être configurée. Comme vous n’avez pas la permission, veuillez en informer votre administrateur.',
    'To generate contract, please configure the': 'Pour générer un contrat, veuillez configurer le',
    'To generate contract, the': 'Pour générer un contrat, le',
    'Contract Number': 'Numéro du contrat',
    'Default Bank Account': 'Compte bancaire par défaut',
    'No expenses for today.': 'Aucune dépense pour aujourd’hui.',
    "Today's To-Do List": 'Liste des tâches d’aujourd’hui',
    'You should be assigned to an agency to access this resource': 'Vous devriez être affecté à une agence pour accéder à cette ressource',
    returns: 'retours',
    pickups: 'ramassages',
    'Stamp must be a positive number': 'Le timbre doit être un nombre positif',
    'Current Contract Number must be a positive number': 'Le numéro de contrat actuel doit être un numéro positif.',
    'Vat is required': 'La TVA est requise',
    'Driver Fee is required': 'Frais de chauffeur requis',
    'Invoice Code': 'Code de facture',
    'Reservation already canceled, completed or not found !': 'Réservation déjà annulée, terminée ou non trouvée !',
    'Rentable is not available for the selected dates!': 'Location n’est pas disponible pour les dates sélectionnées !',
    'Cannot change the type of branch cashbox with movements': 'Impossible de changer le type de caisse avec des mouvements',
    'Some invoices were not found': 'Certaines factures n’ont pas été trouvées',
    'New Branch Cashbox': 'Nouvelle caisse de branche',
    'Create A New Branch Cashbox': 'Créer une nouvelle caisse de branche',
    'Branch Cashboxes': 'Caisse de branches',
    'branch cashboxes': 'Caisse de branches',
    Accounts: 'Comptes',
    'There is': 'Il y a',
    'are reserved are you sure you want to delete them': 'sont réservés et êtes-vous sûr de vouloir les supprimer',
    'Iban must match letters and numbers only. Invalid code format. The code should be between 8 and 14 characters long and follow the pattern: AA-BB-CC-123 or aa-bb-cc-123.': 'Iban doit faire correspondre uniquement les lettres et les chiffres. Format de code invalide. Le code doit comporter entre 8 et 14 caractères et suivre le modèle : AA-BB-CC-123 ou aa-bb-cc-123.',
    'Branch Cashbox': 'Caisse de branche',
    Movements: 'Mouvements',
    'Branch Cashbox Details': 'Détails de caisse de branche',
    'New Movement': 'Nouveau mouvement',
    'Link Invoices': 'Lier les factures',
    In: 'Entrée',
    Out: 'Sortie',
    IN: 'Entrée',
    OUT: 'Sortie',
    'Movement is required': 'Mouvement requis',
    'Create new movement': 'Créer un nouveau mouvement',
    'Description is required': 'Une description est requise',
    'Description must be at most 255 characters': 'La description doit comporter au maximum 255 caractères',
    Movement: 'Mouvement',
    'Invoices are required': 'Les factures sont requises',
    'No invoices!': 'Pas de factures!',
    'No expenses!': 'Pas de dépenses!',
    'CUSTOMER TRANSACTIONS': 'Transaction avec le client',
    'CASH ON HAND': 'Encaisse en main',
    'Created By': 'Créé par',
    demo: {
        title: `France`,
        introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
    },
    'Amount must be a positive number': 'Le montant doit être un nombre positif',
    'Amount must be a number': 'Le montant doit être un nombre',
    'Rentable Availability Check': 'Vérification de la disponibilité de la location',
    'Amount must be greater than 0': 'Le montant doit être supérieur à 0',
    Navigate: 'Naviguer',
    'Availability Check': 'Vérification de la disponibilité',
    'Apologies, but rental': 'Désolé, mais location',
    'is currently occupied by another reservation. Please choose an alternative rentable or use the button below to navigate to the other reservation and close it.': 'est actuellement occupé par une autre réserve. Veuillez choisir une autre option de location ou utiliser le bouton ci-dessous pour accéder à l’autre réservation et la fermer.',
    'Expense updated successfully': 'Dépense mise à jour avec succès',
    Other: 'Autre',
    'Branch cashboxes deleted successfully': "Caisses d'agence supprimées avec succès",
    'Expenses deleted successfully': 'Dépenses supprimées avec succès',
    Open: 'Ouvert',
    Closed: 'Fermé',
    'day(s)': 'jour(s)',
    'Upload Entries': 'Télécharger les entrées',
    'Search by Invoice...': 'Recherche par facture...',
    'Reservation is required': 'Réservation requise',
    'Due date is required': 'La date d’échéance est requise',
    'Status is required': 'État requis',
    'Customer is required': 'Le client est requis',
    'Operation Date': "Date de l'opération",
    UnPay: 'Dépayer',
    Pay: 'Payer',
    'Value Date': 'Date de valeur',
    Credit: 'Crédit',
    'Link Entry': "Lier l'entrée",
    'Entity Identifier': "Identifiant de l'entité",
    'Create & Link': 'Créer & Lier',
    'Download an example': 'Télécharger un exemple',
    Upload: 'Télécharger',
    'Are you sure want to unlink': 'Êtes-vous sûr de vouloir délier',
    Unlink: 'Délier',
    Link: 'Lien',
    'Entity Identifier is required': "L'identifiant de l'entité est requis",
    'All invoices returned successfully': 'Toutes les factures ont été récupérées avec succès',
    'Invoice created successfully': 'Facture créée avec succès',
    'invoice found': 'Facture trouvée',
    'invoices deleted successfully': 'Factures supprimées avec succès',
    'invoice deleted successfully': 'Facture supprimée avec succès',
    'invoice updated successfully': 'Facture mise à jour avec succès',
    'invoice status updated successfully': 'Statut de la facture mis à jour avec succès',
    'invoice unlinked successfully': 'Facture déliée avec succès',
    'expense unlinked successfully': 'Dépense déliée avec succès',
    'invoice not found': 'Facture introuvable',
    'invoice not found or already unlinked': 'Facture introuvable ou déjà déliée',
    'All bank entries returned successfully': 'Toutes les opérations bancaires ont été récupérées avec succès',
    'bank entries created successfully': 'Opérations bancaires créées avec succès',
    'bank entries linked successfully': 'Opérations bancaires liées avec succès',
    'bank Entry deleted successfully': 'Opération bancaire supprimée avec succès',
    'bank entries deleted successfully': 'Opérations bancaires supprimées avec succès',
    'bank account not found': 'Compte bancaire introuvable',
    'bank entry not found': 'Opération bancaire introuvable',
    'bank entry not found or deleted': 'Opération bancaire introuvable ou supprimée',
    'Currency is required': 'La devise est requise',
    Currencies: 'Devises',
    Current: 'Courant',
    Frozen: 'Bloqué',
    Dashboard: 'Tableau de bord',
    'Branch Cashbox Movement': 'Mouvement de Caisse de Branche',
    'Config Value': 'Valeur de configuration',
    'Bank Entry': 'Entrée bancaire',
    'Classes created successfully !': 'Classe créé avec succès!',
    'Classes has been updated successfully !': 'Classe a été mise à jour avec succès !',
    'Customer Transactions': 'Transactions de clients',
    'Amount is Required': 'Le montant est requis',
    'amount must be greater than or equal to 0': 'le montant doit être supérieur ou égal à 0',
    'Create A New Branch Cachbox': 'Créer un nouveau Caisse de Branche',
    'Type is Required': 'Le type est requis',
    'Cash On Hand': 'Encaisse',
    Savings: 'Épargne',
    SAVINGS: 'Épargne',
    Bank: 'Banque',
    'Account Owner Name': 'Nom du propriétaire du compte',
    'Account Owner Name is Required': 'Le nom du propriétaire du compte est requis',
    'Bank Address is Required': "L'adresse bancaire est requise",
    'Account Type is Required': 'Le type de compte est obligatoire',
    'Status is Required': 'Le Statut est requis',
    'Code is Required': 'Le code est requis',
    'Some of the Branch cashbox were not found': "Certaines caisses de branche n'ont pas été trouvées",
    'Currency is Required': 'La devise est requise',
    'Edit Bank Account': 'Modifier Le Compte Bancaire',
    'Edit Branch Cashbox': 'Modifier Caisse de branche',
    'Branch cashbox deleted successfully': 'Caisses de branche supprimées avec succès',
    'Branch cashbox created successfully': 'Caisse de branche créée avec succès',
    'Create New Branch Cashbox': 'Créer une nouvelle caisse de branche',
    'Branch cashbox updated successfully': 'Caisse de branche mise à jour avec succès',
    'Financial Source': 'Ressources Financières',
    Currency: 'Devise',
    'An active CASH_ON_HAND branch cashbox already exists': 'Une caisse de branche de type encaisse active existe déjà',
    'An active CUSTOMER_TRANSACTIONS branch cashbox already exists': 'Une caisse active en agence TRANSACTIONS CLIENTS existe déjà',
    'Account Owner Number': 'Numéro Du Titulaire Du Compte',
    'Account Number': 'Numéro De Compte',
    'Iban Account Number': 'Numéro De Compte IBAN',
    'Bank Name': 'Nom De La Banque',
    'Initial Balance': 'Crédit débit Initiale',
    'Bank Address': 'Adresse De La Banque',
    Balance: 'Crédit débit',
    'Bank Account Details': 'Détails de compte en banque',
    'Bank Entries': 'banques',
    Label: 'Étiquette',
    'Contract number should be up to 8 digits long. Please enter a shorter number.': "Le numéro de contrat doit comporter jusqu'à 8 chiffres. Veuillez saisir un numéro plus court.",
    'The current contract number must not exceed 8 digits.': 'Le numéro de contrat actuel ne doit pas dépasser 8 chiffres.',
    'Occurrence Number must be a positive number greater than 0.': 'Le numéro d’occurrence doit être un nombre positif supérieur à 0.',
    'The export process cannot proceed due to the absence of data.': "Le processus d'exportation ne peut pas se poursuivre en raison de l'absence de données.",
    Cash: 'espèces',
    'Expense config deleted successfully': 'Catégorie de dépenses supprimées avec succès',
    'Expense config updated successfully': 'La Catégorie des dépenses a été mise à jour avec succès',
    'Create Expense Config': 'Créer une Catégorie de dépenses',
    'Expense config created successfully': 'Catégorie des dépenses créée avec succès',
    'Expense config is required': 'La Catégorie des dépenses est requise',
    'User is required': "L'utilisateur est requis",
    'Start Date is required': 'La date de début est requise',
    'Occurrence is required': "L'occurrence est requise",
    'Occurrence Day is required': "Le jour de l'occurrence est requis",
    'End Date is required': 'La date de fin est requise',
    'Occurrence Number is required': "Le numéro d'occurrence est requis",
    bank: 'Banque',
    cash: 'Espèces',
    Close: 'Fermer',
    rentable: 'véhicule',
    Rentable: 'Véhicule',
    'New Agency': 'Nouvelle Agence',
    'This will guide you through the process of creating a new agency for your account.': 'Cela vous guidera tout au long du processus de création d’une nouvelle agence pour votre compte.',
    'To set up a new agency, kindly proceed by clicking on the following link': 'Pour créer une nouvelle agence, veuillez procéder en cliquant sur le lien suivant',
    'Financial source is Required': 'La source financière est requise',
    'Initial balance is Required': 'Le solde initial est requis',
    "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.": "Désolé, nous n'avons pas trouvé la page que vous recherchez. Peut-être avez-vous mal saisi l'URL ? Assurez-vous de vérifier votre orthographe.",
    'Iban must match letters and numbers only.': 'Iban doit correspondre uniquement aux lettres et aux chiffres.',
    "The page you're trying access has restricted access.Please refer to your system administrator": "La page à laquelle vous essayez d'accéder a un accès restreint.Veuillez vous référer à votre administrateur système.",
    'Occurrence Number': "Le numéro d'occurrence",
    'Rentable Extra is required': "L'Extra Rentable est requis",
    'No permission': 'Aucune authorisation',
    'Go to Home': "Aller à la page d'accueil",
    'Please refer to your system administrator': 'Veuillez vous référer à votre administrateur système',
    "The page you're trying access has restricted access.": "La page à laquelle vous essayez d'accéder a un accès restreint.",
    'iban must be at least 5 characters': 'iban doit contenir au moins 5 caractères',
    'New config expense': 'Nouvelle catégorie des dépenses',
    'iban must be at most 34 characters': 'iban doit comporter au maximum 34 caractères',
    'Rentable Extra': 'Équipement et Services',
    'type is required': 'le type est requis',
    'amount is required': 'le montant est requis',
    'payment Method is required': 'Le mode de paiement est requis',
    'FirstName must be at least 2 characters': 'Le prénom doit contenir au moins 2 caractères',
    'LastName must be at least 2 characters': 'Le nom de famille doit contenir au moins 2 caractères',
    'Create a new Config Expense': 'Créer une nouvelle catégorie  des dépenses',
    'Search By Expense Config Name': 'Rechercher par nom de configuration de dépenses',
    'Permission Management': 'Gestion des autorisations',
    Permissions: 'Autorisations',
    'Upload Image': 'Télécharger une image',
    'New Expense Config': 'Nouvelle catégorie  des dépenses',
    'Expense config': 'Catégorie des dépenses',
    'Expense Config': 'Catégorie des dépenses',
    'Edit config expense': 'Modifier la catégorie des dépenses',
    'Edit Expense Config': 'Modifier la catégorie des dépenses',
    'Start Occurrence': "Début de l'occurrence",
    'code must only contain hexadecimal characters': 'le code ne doit contenir que des caractères hexadécimaux',
    'user agency deleted successfully': 'agence utilisateur supprimée avec succès',
    'User already exists': "L'utilisateur existe déjà",
    'Your account has been activated': 'Votre compte a été activé',
    'A new confirmation link has been sent to your email': 'Un nouveau lien de confirmation a été envoyé à votre email',
    'Your account has been created successfully': 'Votre compte a été créé avec succès',
    'Password reset link has been sent to your email': 'Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail',
    '. However, there was an issue sending the reset password email. You can try to resend it from the forget password page, or contact support for assistance.': ". Cependant, un problème est survenu lors de l'envoi de l'e-mail de réinitialisation du mot de passe. Vous pouvez essayer de le renvoyer à partir de la page d'oubli du mot de passe ou contacter l'assistance pour obtenir de l'aide.",
    'User created successfully': 'Utilisateur créé avec succès',
    'Group created successfully': 'Groupe créé avec succès',
    'group deleted successfully': 'groupe supprimé avec succès',
    'Permissions assigned to group successfully': 'Autorisations attribuées au groupe avec succès',
    'Group updated successfully': 'Groupe mis à jour avec succès',
    'Some expense configs not found': 'Certaines configurations de dépenses introuvables',
    'Expense Config not found': 'Configuration des dépenses introuvable',
    'Some expenses are not found': 'Certaines dépenses ne sont pas trouvées',
    'Expense not found': 'Dépense introuvable',
    'Group not found': 'Groupe introuvable',
    'Group name already exists': 'Le nom du groupe existe déjà',
    'Before confirming, make sure to update or add permissions.': "Avant de confirmer, assurez-vous de mettre à jour ou d'ajouter des autorisations.",
    'Invalid or expired confirmation token': 'Jeton de confirmation invalide ou expiré',
    'You have been successfully registered.': 'Vous avez bien été enregistré.',
    'Password must include at least one uppercase letter, one lowercase letter, one digit, and one of the special characters @, $, !, %, *, ?, or #': "Le mot de passe doit inclure au moins une lettre majuscule, une lettre minuscule, un chiffre et l'un des caractères spéciaux @, $, !, %, *, ? ou #",
    Creator: 'Créateur',
    'Expense Config Name': 'Nom de la configuration des dépenses',
    'Expense Config Type': 'Type de configuration des dépenses',
    'Type Recurring': 'Type Récurrent',
    'End Occurrence': "Fin de l'occurrence",
    quarterly: 'Trimestrielle',
    Recurring: 'Récurrente',
    'One Time': 'Une fois',
    'Expense Details': 'Détails des dépenses',
    'Expense Occurrence': 'Occurrence de la dépense',
    Register: 'Registre',
    'Current Contract Number': 'Le numéro de contrat actuel',
    'Time Zone': 'Fuseau Horaire',
    'Expense Type': 'Type de dépense',
    'Create Your Agency': 'Créez votre agence',
    'group is required': 'un groupe est obligatoire',
    'At least one item is required in the array': 'Au moins un élément est requis dans le tableau',
    'Current Contract Number is required': 'Le numéro de contrat actuel est requis',
    'Policy is required': 'Policy est requise',
    'Payment Surcharge is required': 'Un supplément de paiement est requis',
    'Time Zone is required': 'Le fuseau horaire est requis',
    docs: {
        hi: `Bonjour`,
        description: `Besoin d'aide? \n Consultez notre documentation.`,
        documentation: `documentation`,
    },
    'Hours For Next Reservation is required': 'Heures pour la prochaine réservation est requise',
    'You have to assign permissions before confirming': 'Vous devez attribuer des autorisations avant de confirmer',
    'Get started absolutely free.': 'Commencez absolument gratuit.',
    'Please check your email!': 'Merci de consulter vos emails!',
    'We have emailed a 8-digit confirmation code to': 'Nous avons envoyé par e-mail un code de confirmation à 8 chiffres à',
    'please enter the code in below box to verify your email.': 'veuillez saisir le code dans la case ci-dessous pour vérifier votre e-mail.',
    app: `application`,
    'Code is required': 'Le code est requis',
    'Already have an account?': 'Vous avez déjà un compte?',
    'user': 'utilisateur',
    'list': 'liste',
    'edit': 'modifier',
    'shop': 'magasin',
    'blog': 'journal',
    'Verify': 'Confirmer',
    'post': 'publication',
    'seasons': 'périodes',
    'Seasons': 'Périodes',
    'Season': 'Période',
    'season': 'période',
    'PricingGroups': 'Groupes tarifaires',
    'mail': 'courriel',
    'chat': 'bavarder',
    'branches': 'succursales',
    'Branches': 'Succursales',
    'pickup date is required': 'la date de collecte est obligatoire',
    'branch': 'Succursale',
    'Branch': 'Succursale',
    'cards': 'cartes',
    'posts': 'publications',
    'create': 'concevoir',
    'kanban': 'kanban',
    'general': 'général',
    'banking': 'financier',
    'booking': 'réservation',
    'profile': 'profil',
    'account': 'compte',
    'product': 'article',
    'invoice': 'reçu',
    'details': 'Informations',
    'checkout': 'valider',
    'calendar': 'agenda',
    'analytics': 'statistiques',
    'ecommerce': 'commerce en ligne',
    'management': 'gestion',
    menu_level: `niveau menu`,
    menu_level_2a: `niveau menu 2a`,
    menu_level_2b: `niveau menu 2b`,
    menu_level_3a: `niveau menu 3a`,
    menu_level_3b: `niveau menu 3b`,
    menu_level_4a: `niveau menu 4a`,
    menu_level_4b: `niveau menu 4b`,
    'Search by Name...': 'Rechercher par nom...',
    item_disabled: `élément désactivé`,
    item_label: `étiquette de l'article`,
    item_caption: `légende de l'article`,
    item_external_link: `lien externe de l'article`,
    description: `la description`,
    other_cases: `autres cas`,
    item_by_roles: `élément par rôles`,
    only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
    'Bank Name is Required': 'Le nom de la banque est obligatoire',
    Jan: 'Janv',
    Feb: 'Févr',
    Mar: 'Mars',
    Apr: 'Avr',
    May: 'Mai',
    Jun: 'Juin',
    Jul: 'Juil',
    Aug: 'Août',
    Sep: 'Sept',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Déc',
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
    'Total Rentables Count': 'Nombre total de locations',
    'Total Customer Count': 'Nombre total de clients',
    'Total Branch Count': 'Nombre total de branches',
    'Annual Revenue Overview': 'Aperçu annuel des revenus',
    'Annual Expenses Overview': 'Aperçu annuel des dépenses',
    'Annual Net Income Overview': 'Aperçu annuel du bénéfice net',
    'This Year': 'Cette année',
    income: 'Revenu',
    outcome: 'Dépense',
    'Rentals Pick-up and Return Trends by Date': 'Tendances de ramassage et de retour des locations par date',
    'Class-based Financial Overview: Rentables Income vs. Expenses': 'Aperçu financier par classe : Revenus des locations par rapport aux dépenses',
    'Income and Expenses Trend Analysis by Date': 'Analyse de tendance des revenus et des dépenses par date',
    'Reservation Distribution by Rentable Pricing Group': 'Répartition des réservations par groupe de tarification',
    'No data available': 'Aucune donnée disponible',
    'No Data': 'Aucune donnée',
    'Rentables Pick-up and Return Trends by Date': 'Tendances de ramassage et de retour des locations par date',
    'Reservation Distribution by Class': 'Répartition des réservations par classe',
    'Pick up': 'Ramassage',
    'Pick Up': 'Ramassage',
    PickUp: 'Ramassage',
    Pickup: 'Ramassage',
    Return: 'Retour',
    'Daily Income Distribution by Branch': 'Répartition quotidienne des revenus par branche',
    Today: "Aujourd'hui",
    Yesterday: 'Hier',
    'This Week': 'Cette semaine',
    'Last Week': 'La semaine dernière',
    'This Month': 'Ce mois-ci',
    'Last Month': 'Dernier mois',
    'days up to today': "jours jusqu'à aujourd'hui",
    'days starting today': "jours à partir d'aujourd'hui",
    Confirm: 'Confirmer',
    Clear: 'Effacer',
    "Today's Rentables Report": "Rapport sur les locations de aujourd'hui",
    Logout: 'Déconnexion',
    'Last 10 Reservation Records': '10 derniers enregistrements de réservation',
    'Pickup Address': 'Adresse de ramassage',
    'Start Date': 'Date de début',
    'End Date': 'Date de fin',
    Status: 'Statut',
    'View All': 'Voir tout',
    State: 'État',
    City: 'Ville',
    Address: 'Adresse',
    'Postal Code': 'Code postal',
    'is Hq': 'Est le siège social',
    'Is Hq': 'Est le siège social',
    'Search by Name': 'Rechercher par nom',
    'New Branch': 'Nouvelle branche',
    'New branch': 'Nouvelle branche',
    'Create A New Branch': 'Créer une nouvelle branche',
    'Create A New Class': 'Créer une nouvelle classe',
    Settings: 'Paramètres',
    settings: 'Paramètres',
    ', and proceed with creating a new expense configuration.': ", puis procédez à la création d'une nouvelle configuration de dépenses.",
    'section, access': ' accès',
    "You'll need to navigate to the": 'Vous devrez naviguer vers le section de ',
    'How much surcharge the max daily km?': 'Quel est le montant de la majoration pour le kilométrage quotidien maximal ?',
    'Require Transfer Cost': 'Coût du transfert requis',
    'Require transfer cost': 'Coût du transfert requis',
    'How much surcharge the deferred payment?': 'Quel est le montant de la majoration pour le paiement différé ?',
    'Do you allow additional driver in your reservations?': 'Autorisez-vous des conducteurs supplémentaires dans vos réservations ?',
    'Deferred Payment Surcharge': 'Majoration pour paiement différé',
    'Deferred payment surcharge': 'Majoration pour paiement différé',
    'Transfer Base Cost': 'Coût de base du transfert',
    'Allow Additional Driver': 'Autoriser un conducteur supplémentaire',
    'Allow additional driver': 'Autoriser un conducteur supplémentaire',
    'Allow Vat Taxas': 'Permettre les taxes sur la TVA',
    'Allow vat taxas': 'Permettre les taxes sur la TVA',
    'Fuel policy': 'Politique de carburant',
    'Fuel policy ': 'Politique de carburant',
    'Fuel Policy': 'Politique de carburant',
    Full: 'Plein',
    Partial: 'Partiel',
    Empty: 'Vide',
    'Require Transfer Cost?': 'Coût du transfert requis ?',
    'How much km per day?': 'Combien de kilomètres par jour ?',
    ExceededMaxDailyKmSurcharge: 'Majoration pour dépassement du kilométrage quotidien maximal',
    'Required Deposit': 'Dépôt requis',
    Description: 'Description',
    'Drop files here or click': 'Déposez les fichiers ici ou cliquez',
    browse: 'Parcourir',
    'thorough your machine': 'à travers votre machine',
    'Is Headquarters': 'Est le siège social',
    'Create Branch': 'Créer une branche',
    'Choose your Fuel Policy': 'Choisissez votre politique de carburant',
    'Nbr Hours For Next Reservation': "Nombre d'heures pour la prochaine réservation",
    'Action Type': "Type d'action",
    'Rentable Type': 'Type de location',
    'Search by Registration Number...': "Rechercher par numéro d'immatriculation...",
    'Registration Number': "Numéro d'immatriculation",
    'R.N.': 'N.Im.',
    'Payment Method': 'Méthode de paiement',
    'Payment Methods': 'Méthode de paiement',
    'Edit an Expense': 'Modifier une dépense',
    Amount: 'Montant',
    'Created At': 'Créé(e) le',
    'Updated At': 'Mis à jour à',
    'Create Expense': 'Créer une dépense',
    Brand: 'Marque',
    Rentables: 'Locations',
    'New Rentable': 'Nouvelle location',
    'Create a new rentable': 'Créer une nouvelle location',
    'Create Rentable': 'Créer une location',
    'Max Daily Km': 'Kilométrage quotidien maximal',
    'Young Vehicle Surcharge Percentage': 'Pourcentage de majoration pour les véhicules récents',
    'Young Vehicle Surcharge': 'Majoration pour véhicule récent',
    'Young Vehicle Month Limit': 'Limite de mois pour les véhicules récents',
    'Create Class': 'Créer une classe',
    'Search by Class Name...': 'Rechercher par nom de classe...',
    'Default Daily Price': 'Prix journalier par défaut',
    'Three Days Daily Price': 'Prix journalier pour trois jours',
    'Week Daily Price': 'Prix journalier pour une semaine',
    'Daily Price': 'Prix journalier',
    'Month Daily Price': 'Prix journalier pour un mois',
    'Select Start Date ...': 'Sélectionnez la date de début...',
    'Select End Date ...': 'Sélectionnez la date de fin...',
    'Started On': 'Commencé le',
    'Finished On ': 'Terminé le ',
    'Min Day': 'Jour minimum',
    'Season Occurence': 'Occurrence de saison',
    'New Season': 'Nouvelle saison',
    Monthly: 'Mensuel',
    Annual: 'Annuel',
    monthly: 'Mensuel',
    annual: 'Annuel',
    'Season Name': 'Nom de la saison',
    'Is Periodic': 'Est périodique',
    'Create Season': 'Créer une saison',
    'Default Three Days Daily Price': 'Prix journalier par défaut pour trois jours',
    'Default Week Daily Price': 'Prix journalier par défaut pour une semaine',
    'Default Month Daily Price': 'Prix journalier par défaut pour un mois',
    'Add Pricing Group Season': 'Ajouter une saison de groupe tarifaire',
    'Pricing Group Season': 'Saison de groupe tarifaire',
    'Pricing Group Seasons': 'Tarification des saisons de groupe',
    'Add Rentables': 'Ajouter des locations',
    Edit: 'Modifier',
    Tunisian: 'Tunisienne',
    tunisian: 'Tunisienne',
    View: 'Voir',
    Delete: 'Supprimer',
    Cars: 'Voitures',
    // Rentable: 'Location',
    Customer: 'Client',
    'Return Date': 'Date de retour',
    'Pick Up Date': 'Date de ramassage',
    'Total Amount': 'Montant total',
    'Total Days': 'Nombre total de jours',
    "Today's Pick Up": 'Ramassage du jour',
    "Tomorrow's Pick Up": 'Ramassage de demain',
    "Today's Return": 'Retour du jour',
    "Tomorrow's Return": 'Retour de demain',
    Pending: 'En attente',
    pending: 'En attente',
    'In progress': 'En cours',
    'In Progress': 'En cours',
    inProgress: 'En cours',
    Completed: 'Terminé',
    completed: 'Terminé',
    Canceled: 'Annulé',
    canceled: 'Annulé',
    "Customer's itinerary": 'Itinéraire du client',
    'Select Rentable': 'Sélectionner une location',
    'Reserve the Rentable': 'Réserver la location',
    'Pickup Location': 'Lieu de ramassage',
    'Pickup location': 'Lieu de ramassage',
    'Return at the same place': 'Retour au même endroit',
    'Back to reservations list': 'Retour à la liste des réservations',
    'Next Step': 'Étape suivante',
    Next: 'Suivant',
    'No options': 'Aucune option',
    'Reservation Process': 'Processus de réservation',
    Boats: 'Bateaux',
    Bicycles: 'Vélos',
    Motorcycles: 'Motos',
    Summary: 'Résumé',
    From: 'De',
    To: 'à',
    'Total Price': 'Prix total',
    Total: 'Totale',
    'Cost Transfer': 'Coût du transfert',
    'Create A New Bank Account': 'Créer un nouveau compte bancaire',
    costTransfer: 'Coût du transfert',
    Overload: 'Surcharge',
    'Discount Price': 'Prix remisé',
    'Total Extra Price': 'Prix total des extras',
    Discount: 'Remise',
    'New Bank Account': 'Nouveau compte bancaire',
    'Bank Account': 'Compte bancaire',
    'Manual Price': 'Prix manuel',
    'Exclude cost transfer, include tax if applicable': 'Exclure le transfert des coûts, Inclure la taxe, le cas échéant',
    'Account Type': 'Type De Compte',
    Back: 'Retour',
    'Bank Accounts': 'Comptes bancaires',
    'Select Customer': 'Sélectionner le client',
    'First Conductor': 'Premier conducteur',
    'Select First Conductor': 'Sélectionnez le premier conducteur',
    Add: 'Ajouter',
    cancel: 'Annuler',
    'check out': 'Payer',
    'Check Out': 'Payer',
    Book: 'Réserver',
    'Return Location': 'Lieu de retour',
    'No rentable is available for the dates provided!': "Aucun locatif n'est disponible pour les dates indiquées!",
    'Consider selecting an alternative pricing group and exploring other options.': 'Songez à sélectionner une autre catégorie de tarification et à indexer autres options.',
    'Are you sure you want to pay?': 'Tu es sûr de vouloir payer ?',
    'Return location': 'Lieu de retour',
    'Add Customer As FirstConductor': 'Ajouter le client en tant que premier conducteur',
    'Due Date': "Date d'échéance",
    'Equipment & Services': 'Équipement et services',
    Extras: 'Suppléments',
    Drivers: 'Conducteurs',
    'Select All': 'Tout sélectionner',
    'Id Card Number': "Numéro de carte d'identité",
    Phone: 'Téléphone',
    'Payment type': 'Type de paiement',
    Payment: 'Paiement',
    Cancel: 'Annuler',
    Contract: 'Contrat',
    Details: 'Détails',
    Nationality: 'Nationalité',
    Passport: 'Passeport',
    'Pricing groups': `Groupes de tarification`,
    'pricing groups': `Groupes de tarification`,
    'Driving License': 'Permis de conduire',
    'Residence Permit': 'Permis de séjour',
    'Date Of Birth': 'Date de naissance',
    'Ban Customer': 'Bloquer le client',
    'Are you sure want to Ban?': 'Êtes-vous sûr de vouloir bloquer ?',
    Ban: 'Bloquer',
    'TIMELINE WEEK': 'PLANNING DE LA SEMAINE',
    'TIMELINE MONTH': 'PLANNING DU MOIS',
    'There is no data': "Il n'y a pas de données",
    'agency customer not found or deleted': "Client de l'agence introuvable ou supprimé",
    'User not registered': 'Utilisateur non enregistré',
    'Credential not set': "Informations d'identification non définies",
    'Authentication failure': "Échec de l'authentification",
    'Invalid access token': "Jeton d'accès invalide",
    'Branch Location not found or deleted': 'Emplacement de branche introuvable ou supprimé',
    'the name of the branch already exists': 'Le nom de la branche existe déjà',
    'Branch not found or deleted': 'branche introuvable ou supprimée',
    'One or many rentables not found': 'Un ou plusieurs objets à louer introuvables',
    'Brand not found': 'Marque introuvable',
    'City not found': 'Ville introuvable',
    'Class not found or deleted': 'Classe introuvable ou supprimée',
    'Config not found or deleted': 'Configuration introuvable ou supprimée',
    'The chosen config is not related to class': "La configuration choisie n'est pas liée à la classe",
    'Rentable type not found or deleted': "Type d'objet à louer introuvable ou supprimé",
    'Config value not found or deleted': 'Valeur de configuration introuvable ou supprimée',
    'Config value scope is not valid': "La portée de la valeur de configuration n'est pas valide",
    'Agency is not valid': "L'agence n'est pas valide",
    'Config is not valid': "La configuration n'est pas valide",
    'class not found': 'Classe introuvable',
    'Some classes not found or have been deleted': 'Certaines classes introuvables ou supprimées',
    'Config not found': 'Configuration introuvable',
    'value of given config not found': 'valeur de configuration donnée introuvable',
    'ConfigValue not found': 'Valeur de configuration introuvable',
    'Customer already exists': 'Le client existe déjà',
    'customer not found or deleted': 'Client introuvable ou supprimé',
    'Expense not found or deleted': 'Dépense introuvable ou supprimée',
    'Location not found or deleted': 'Emplacement introuvable ou supprimé',
    'ModelImage not found': 'Image de modèle introuvable',
    'Model not found': 'Modèle introuvable',
    'season not found': 'Saison introuvable',
    'you cannot create new pricing group season with used season': 'Vous ne pouvez pas créer une nouvelle saison de groupe de tarification avec une saison utilisée',
    'pricing group not found': 'Groupe de tarification introuvable',
    'pricing group season not found or deleted': 'Saison de groupe de tarification introuvable ou supprimée',
    'pricing group season not found': 'Saison de groupe de tarification introuvable',
    'Some pricing groups not found or have been deleted': 'Certains groupes de tarification introuvables ou supprimés',
    'pricing group not found or deleted': 'Groupe de tarification introuvable ou supprimé',
    'pricingGroup not found': 'Groupe de tarification introuvable',
    'there is no rentable available': "Il n'y a pas d'objet à louer disponible",
    'rentableAttribute not found': "Attribut d'objet à louer introuvable",
    'rentableAttribute not found or deleted': "Attribut d'objet à louer introuvable ou supprimé",
    'rentable attribute value not found': "Valeur d'attribut d'objet à louer introuvable",
    'rentable attribute not found': "Attribut d'objet à louer introuvable",
    'Rentables not found or deleted': 'Objets à louer introuvables ou supprimés',
    'rentable type is not extra': "Le type d'objet à louer n'est pas un supplément",
    'Rentable not found or deleted': 'Objet à louer introuvable ou supprimé',
    'branch not found': 'branche introuvable',
    'RentableExtraType not found': "Type de supplément d'objet à louer introuvable",
    'Invalid rentable type': "Type d'objet à louer invalide",
    'RentableType not found': "Type d'objet à louer introuvable",
    'Reservation Payment Method not found or deleted': 'Méthode de paiement de la réservation introuvable ou supprimée',
    'reservation item not found': 'Élément de réservation introuvable',
    'Permission denied': 'Autorisation refusée',
    'Invalid Authorization': 'Autorisation invalide',
    'Invalid Access Token': "Jeton d'accès invalide",
    'Required configuration values for km exceed not found': 'Valeurs de configuration requises pour le dépassement de kilométrage introuvables',
    'unable to calculate extra total price after pickup date change': 'Impossible de calculer le prix total supplémentaire après modification de la date de ramassage',
    "pickup date can't be after the return date": 'La date de ramassage ne peut pas être postérieure à la date de retour',
    'unable to calculate rentable total price after pickup date change': "Impossible de calculer le prix total de l'objet à louer après modification de la date de ramassage",
    'Rentable group not found or deleted': "Groupe d'objets à louer introuvable ou supprimé",
    'Pricing group not found or deleted': 'Groupe de tarification introuvable ou supprimé',
    'unable to calculate rentable total price after return date change': "Impossible de calculer le prix total de l'objet à louer après modification de la date de retour",
    'You are not allowed to make reservations for this rentable': "Vous n'êtes pas autorisé à effectuer des réservations pour cet objet à louer",
    'pickup location is required': "L'emplacement de ramassage est requis",
    // 'There is an overlap with an existing reservation (${existingReservation.code})':
    //   'Il y a un chevauchement avec une réservation existante (${existingReservation.code})',
    'There is no available rentable for the selected dates': "Il n'y a aucun objet à louer disponible pour les dates sélectionnées",
    'No rentable available for the dates provided': 'Aucun objet à louer disponible aux dates fournies',
    'rentable type not found': "Type d'objet à louer introuvable",
    'Invalid user': 'Utilisateur non valide',
    'user not found': 'Utilisateur introuvable',
    'The user you are looking for could not be found or has been deleted!': "L'utilisateur que vous recherchez est introuvable ou a été supprimé !",
    'The selected location is not available for pickup': "L'emplacement sélectionné n'est pas disponible pour le ramassage",
    'The selected location is not available for return': "L'emplacement sélectionné n'est pas disponible pour le retour",
    'Invalid email or password': 'Adresse e-mail ou mot de passe invalide',
    'Invalid email format': "Format d'adresse e-mail invalide",
    'Email already in use': 'Adresse e-mail déjà utilisée',
    'Password reset token invalid or expired': 'Jeton de réinitialisation du mot de passe invalide ou expiré',
    'Unable to send password reset email': "Impossible d'envoyer l'e-mail de réinitialisation du mot de passe",
    'Reset password token not found or expired': 'Jeton de réinitialisation du mot de passe introuvable ou expiré',
    'Unable to reset password': 'Impossible de réinitialiser le mot de passe',
    'Cannot deactivate current user account': 'Impossible de désactiver le compte utilisateur actuel',
    'Invalid verification token': 'Jeton de vérification invalide',
    'Unable to verify email': "Impossible de vérifier l'adresse e-mail",
    'User with this email not found': 'Utilisateur avec cette adresse e-mail introuvable',
    'verification token not found or expired': 'Jeton de vérification introuvable ou expiré',
    'User already verified': 'Utilisateur déjà vérifié',
    Unauthorized: 'Non autorisé',
    'Request failed with status code 404': "Requête échouée avec le code d'état 404",
    'reservations without available cars': 'réservations sans voitures disponibles',
    'Please check and take necessary actions.': 'Veuillez vérifier et prendre les mesures nécessaires.',
    'Reservations without available cars:': 'Réservations sans voitures disponibles :',
    'Total Reservations Count': 'Nombre total de réservations',
    'See details': 'Voir les détails',
    Available: 'Disponible',
    'in circulation': 'en circulation',
    Prices: 'Prix',
    'Month Daily Price is required': 'Le prix journalier du mois est requis',
    'Week Daily Price is required': 'Le prix journalier de la semaine est requis',
    'Three Days Daily Price is required': 'Le prix journalier de trois jours est requis',
    'Daily Price is required': 'Le prix journalier est requis',
    'Season is required': 'La saison est requise',
    'There are': 'Il y a',
    'reservations without available cars. Please check and take necessary actions': 'des réservations sans voitures disponibles. Veuillez vérifier et prendre les mesures nécessaires',
    'Save Changes': 'Enregistrer les modifications',
    'Last 7 Days': 'Les 7 derniers jours',
    'Extra Driver Fee': 'Frais conducteur supplémentaire',
    'Extra driver fee': 'Frais conducteur supplémentaire',
    'Transfer Km Cost': 'Coût du kilomètre de transfert',
    'Transfer km cost': 'Coût du kilomètre de transfert',
    'Number Hours For Next ReserVation': "Nombre d'heures pour la prochaine réservation",
    'Number Hours For Next Reservation': "Nombre d'heures pour la prochaine réservation",
    'number hours for next reservation': "Nombre d'heures pour la prochaine réservation",
    'Rentable Type is required': 'Le type de location est requis',
    Name: 'Nom',
    'Customer banned successfully': 'Client banni avec succès',
    'Customer(s) banned successfully': 'Client(s) banni(s) avec succès',
    'customer banned': 'client banni',
    banned: 'banni',
    Banned: 'Banni',
    'Customer Details': 'Détails du client',
    'Something went wrong': "Quelque chose s'est mal passé",
    'Start date must be after current date': 'La date de début doit être postérieure à la date actuelle',
    'You Cannot drag a reservation in Class Cell': 'Vous ne pouvez pas déplacer une réservation dans la cellule de classe',
    'Only Pending Reservations are Draggable': 'Seules les réservations en attente peuvent être déplacées',
    'Customer and First Conductor are required': 'Le client et le premier conducteur sont requis',
    'You have to choose a pricing group': 'Vous devez choisir  groupe de tarifaire',
    'SELECT DATE & TIME': "SÉLECTIONNEZ LA DATE ET L'HEURE",
    'Return Location is required': "L'emplacement de retour est requis",
    'Pickup Location is required': "L'emplacement de ramassage est requis",
    'End date must be after start date': 'La date de fin doit être postérieure à la date de début',
    'Identity Card Information': "Informations de la carte d'identité",
    'First Conductor Information': 'Informations du premier conducteur',
    'Phone Number': 'Numéro de téléphone',
    'First Conductor License information': 'Informations sur le permis de conduire du premier conducteur',
    'Driving License Delivered Date': 'Date de délivrance du permis de conduire',
    'Second Conductor': 'Deuxième Conducteur',
    'Select Second Conductor': 'Sélectionner le deuxième conducteur',
    'second Conductor passport Number is required': 'Le numéro de passeport du deuxième conducteur est requis',
    'second Conductor id Card Number is required': "Le numéro de carte d'identité du deuxième conducteur est requis",
    'second Conductor firstName is required': 'Le prénom du deuxième conducteur est requis',
    'second Conductor lastName is required': 'Le nom de famille du deuxième conducteur est requis',
    'second Conductor nationality is required': 'La nationalité du deuxième conducteur est requise',
    'second Conductor address is required': "L'adresse du deuxième conducteur est requise",
    'second Conductor phone is required': 'Le numéro de téléphone du deuxième conducteur est requis',
    'Passport Number': 'Numéro de passeport',
    AllowAdditionalDriver: 'Autoriser un conducteur supplémentaire',
    ExtraDriverFee: 'Frais conducteur supplémentaire',
    RequireTransferCost: 'Coût du transfert requis',
    TransferBaseCost: 'Coût de transfert de base',
    TransferKmCost: 'Coût du kilomètre de transfert',
    MaxDailyKm: 'Kilométrage quotidien maximal',
    'Class': 'Catégorie',
    'CreatedAt': 'Créé(e) à',
    'Modal': 'Fenêtre',
    'Login': 'Identification',
    'Print': 'Éditer',
    'Expenses': 'Frais',
    'Expense': 'Frais',
    'fleet': 'Parc',
    'pricing': 'fixation des prix',
    'Pricing': 'Fixation des prix',
    'customers': 'consommateurs',
    'Customers': 'Consommateurs',
    'customer': 'consommateur',
    'Actions': 'Opérations',
    'Download': 'Téléversement',
    'dashboard': 'Tableau de bord',
    'ID Card': "Carte d'identité",
    'Search by Name, ID Card, Phone, Driving License, or Residence Permit...': 'Recherche par nom, carte d’identité, téléphone, permis de conduire ou permis de résidence...',
    'Are you sure want to delete': 'Êtes-vous sûr de vouloir supprimer',
    selected: 'sélectionné',
    'Are you sure want to delete?': 'Êtes-vous sûr de vouloir supprimer ?',
    'New Expenses': 'Nouvelles dépenses',
    'Pricing Group Details': 'Détails du groupe de tarification',
    'Reservations Agenda': 'Agenda des réservations',
    'No Rentable available!': 'Aucune location disponible !',
    'Sorry, page not found!': 'Désolé, page introuvable !',
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.': "Désolé, nous n'avons pas pu trouver la page que vous recherchez. Peut-être avez-vous mal saisi l'URL ? Veuillez vérifier l'orthographe.",
    'Go To Home': "Aller à l'accueil",
    Calendar: 'Calendrier',
    'Need Help?': "besoin d'aide?",
    'New Expense': 'Nouvelle dépense',
    Update: 'Mettre à jour',
    Password: 'Mot de passe',
    'Email address': 'Adresse e-mail',
    'Sign in': 'Se Connecter',
    'Last 10 Return Records': '10 derniers enregistrements de retour',
    'Last 10 Pickup Records': '10 derniers enregistrements de ramassage',
    'Branch contain reservations, if you confirm you need to update them manually.': 'La branche contient des réservations, si vous confirmez, vous devez les mettre à jour manuellement.',
    items: 'éléments',
    'Add Extras': 'Ajouter des suppléments',
    'Show Extras': 'Afficher les extras',
    'Hide Extras': 'Masquer les extras',
    'Fuel Airconditional': 'Carburant conditionnel',
    'Transmission Drive': 'Moteur de transmission',
    'Branch Details': 'Détails de la branche',
    'New Class': 'Nouvelle Classe',
    'Edit Expense': 'Modifier une dépense',
    'Edit season': 'Modifier une saison',
    'Edit class': 'Modifier une classe',
    'color Is Required': 'La couleur est requise',
    color: 'couleur',
    Category: 'Catégorie',
    Classes: 'Classes',
    Color: 'Couleur',
    'Pricing Groups': `Groupes de tarification`,
    'Pricing Group': `Groupe de tarification`,
    'New Group': 'Nouveau Groupe',
    'Edit Group': 'modifier un groupe',
    'Edit Pricing Group': 'Modifier le groupe de tarification',
    'Create Group': 'Créer un groupe',
    'New group': 'Nouveau Groupe',
    'New reservation': 'Nouvelle réservation',
    'Edit rentable': 'Modifier la location',
    'Rentable Details': 'Détails de la location',
    'Edit Reservation': 'Modifier la réservation',
    Submit: 'Soumettre',
    'Upload files': 'Télécharger des fichiers',
    'Remove all': 'Supprimer tout',
    'Choose your car (or similar)': 'Choisissez votre voiture (ou similaire)',
    'rentable not available at the given date, please choose another date or explore other rentals!': "location non disponible à la date indiquée, veuillez choisir une autre date ou explorer d'autres locations !",
    True: 'Vrai',
    False: 'Faux',
    filters: 'Filtres',
    group: 'groupe',
    class: 'classe',
    Filter: 'Filtre',
    filter: 'Filtre',
    Filters: 'Filtres',
    AVAILABLE: 'DISPONIBLE',
    Quantity: 'Quantité',
    Price: 'prix',
    'Reservation Details': 'Détails de la réservation',
    'select your car or similar': 'sélectionnez votre voiture ou similaire',
    'Class Details': 'Détails de la classe',
    'Extra Type': 'Type supplémentaire',
    'Branch Locations': 'Localisations des branches',
    'Drop or Select file': 'Déposer ou sélectionner un fichier',
    'Edit branch': 'Modifier la branche',
    'Can Transfer': 'Peut transférer',
    Location: 'Localisations',
    'Distance (Km)': 'Distance (Km)',
    Remove: 'Retirer',
    'Add a branch location': 'Ajouter une localisation de branche',
    None: 'aucun',
    leasing: 'leasing',
    washing: 'lavage',
    fuel: 'Carburant',
    maintains: 'entretien',
    other: 'autre',
    case: 'Espèce',
    transfer: 'virement',
    All: 'tout',
    day: 'jour',
    'Expenses | Edit Expense': 'Dépenses | Modifier la dépense',
    'Expenses | Create Expense': 'Dépenses | Créer une dépense',
    'Number  Hours  For  Next  Reser Vation': 'Nombre d’heures pour la prochaine réservation ',
    'How many hours do you need to prepare your rentable for the next reservation?': 'Combien d’heures avez-vous besoin pour préparer votre location pour la prochaine réservation?',
    'If you allow extra driver, how much money do he need to pay?': 'Si vous autorisez un conducteur supplémentaire, combien d’argent doit-il payer?',
    'Do you allow VAT tax?': 'Autorisez-vous la TVA?',
    'Allow  Vat  Taxas': 'Permettre les taxes sur la TVA',
    'Require  Transfer  Cost': 'Coût de transfert requis',
    'ACRISS Code': 'Code de l’ACRISS',
    'Pricing group': 'Groupe de prix',
    'Rental Type': 'Type de locatoin',
    'Finished On': 'Terminé le',
    'Season occurrence': 'Apparition des saisons',
    'There Is Reservation For This Rentable Are You Sure You Want To Delete It': 'Il ya réservation pour cette location sont vous sûr que vous voulez le supprimer',
    'Pickup date': 'Date de ramassage',
    'Date of birth': 'Date de naissance',
    'First name': 'Prénom',
    'Last name': 'Nom de famille',
    'Driving license number': 'Numéro de permis de conduire',
    'Driving license delivered date': 'Date de délivrance du permis de conduire',
    'Id card number': "Numéro de carte d'identité",
    'Id card delivered date': "Date de délivrance de la carte d'identité",
    'Passport number': 'Numéro de passeport',
    'Passport delivered date': 'Date de délivrance du passeport',
    // yup messages
    'Name is required': 'Le nom est requis',
    'Name must be at least 2 characters': 'Le nom doit comporter au moins deux caractères',
    'Country is required': 'Le Pays est requis',
    'State is required': `l'État est requis`,
    'City is required': 'la ville est requis',
    'Address is required': `L'Adresse est requis`,
    'Address must be at least 4 characters': `l’adresse doit compter au moins 4 caractères`,
    'Postal Code is required': `Code postal requis`,
    'Postal Code must be more than 4 digits and not null': `Le code postal doit avoir plus de 4 chiffres et ne pas être nul`,
    'Location is required': `Localisation est requis`,
    'Distance must be a positive number': `La distance doit être un nombre positif`,
    'Distance is required': `Distance requise`,
    'Distance is required and must be a number': `La distance est requise et doit être un nombre`,
    'Extra Driver Fee is required': `Des frais de conducteur supplémentaires sont requis`,
    'Extra Driver Fee must be a positive number': `Les frais de conducteur supplémentaire doivent être un nombre positif`,
    'Transfer Base Cost is required': `Coût de base du transfert requis`,
    'Transfer Km Cost is required': `Transfert Km Coût requis`,
    'Required Deposit is required': `Dépôt requis`,
    'Transfer Base Cost must be a positive number': `Le coût de base du transfert doit être un chiffre positif`,
    'Transfer Km Cost must be a positive number': `Le coût du km de transfert doit être un nombre positif`,
    'Max Daily Km must be a positive number': `Le nombre maximal de kilomètres quotidiens doit être positif`,
    'Exceeded Max Daily Km Surcharge must be a positive number': `Dépassement du supplément de kilométrage quotidien maximal doit être un nombre positif`,
    'Des frais de conducteur supplémentaires sont requis': `Des frais de conducteur supplémentaires sont requis`,
    'Max Daily Km is required': `Km quotidien maximum requis`,
    'Exceeded Max Daily Km Surcharge is required': `Dépassement du kilométrage quotidien maximal requis`,
    'Young Vehicle Month Limit is required': `La limite mensuelle pour les jeunes véhicules est requise`,
    'Young Vehicle Surcharge Percentage must be a positive number': `Le pourcentage de supplément pour jeune véhicule doit être positif`,
    'Young Vehicle Surcharge must be a positive number': `Le supplément pour jeune véhicule doit être un nombre positif`,
    'Young Vehicle Month Limit must be a positive number': `La limite mensuelle du jeune véhicule doit être positive`,
    'Acriss Category is required': `La catégorie Acriss est requise`,
    'Acriss Fuel Airconditional is required': `Acriss Fuel Airconditional est requis`,
    'Acriss Transmission Drive is required': `Le lecteur de transmission Acriss est requis`,
    'Acriss Type is required': `Type d’acriss requis`,
    'Active Is Required': `Actif est requis`,
    'Rentable Modal Is Required': `Mode de location requis`,
    'Class Is Required': `La classe est requise`,
    'Brand Is Required': `La marque est requise`,
    'Rentable Type Is Required': `Type de location requis`,
    'Branch Is Required': `La branche est requise`,
    'Disabled From is Required': `Disabled From est requis`,
    'Registration number Is Required': `Numéro d’enregistrement requis`,
    'Color Is Required': `La couleur est requise`,
    'Registration number must be at least 3 characters': `Le numéro d’enregistrement doit comporter au moins trois caractères`,
    'Pricing Group Is Required': `Groupe de tarification requis`,
    'Daily Price must be a positive number': `Le prix quotidien doit être un nombre positif`,
    'Three DaysDailyPrice must be a positive number': `Trois DaysDailyPrice doit être un nombre positif`,
    'Week DailyPrice must be a positive number': `Semaine DailyPrice doit être un nombre positif`,
    'Month DailyPrice must be a positive number': `Mois DailyPrice doit être un nombre positif`,
    'Min Day is required': 'Le nombre minimum de jours est requis',
    'Start date is required': 'La date de début est requise',
    'End date is required': 'La date de fin est requise',
    'Amount is required': 'Montant requis',
    'Amount must be positive number': 'Le montant doit être positif',
    'Rentable is required': 'Louage requis',
    'Type is required': 'Type requis',
    'Payment Method is required': 'Mode de paiement requis',
    'Date is required': 'Date est requise',
    'Rental Type is required': 'Type de location requis',
    'Rental Extra Type is required': 'Type de location supplémentaire requis',
    'Name must be at least 3 characters': 'Le nom doit comporter au moins trois caractères',
    'Price is required': 'Prix requis',
    'Quantity is required': 'Quantité requise',
    'Price must be greater than or equal to 0': 'Le prix doit être supérieur ou égal à 0',
    'Three Days Daily Price must be a positive number': 'Le prix quotidien de trois jours doit être un nombre positif',
    'Week Daily Price must be a positive number': 'Le prix quotidien de la semaine doit être positif',
    'Month Daily Price must be a positive number': 'Le prix quotidien mensuel doit être positif',
    'Delivered date must be less than or equal to the current day': 'La date de livraison doit être antérieure ou égale à la date actuelle',
    'Customer first name is required': 'Le prénom du client est requis',
    'Customer first name must be at least 3 characters': 'Le prénom du client doit comporter au moins 3 caractères',
    'Customer last name must be at least 3 characters': 'Le nom de famille du client doit comporter au moins 3 caractères',
    'Customer must be at least 18 years old': 'Le client doit avoir au moins 18 ans',
    'Customer date of birth is required': 'La date de naissance du client est requise',
    'Customer nationality is required': 'La nationalité du client est requise',
    'Customer nationality must be at least 3 characters': 'La nationalité du client doit comporter au moins 3 caractères',
    'Customer address is required': "L'adresse du client est requise",
    'Customer address must be at least 4 characters': "L'adresse du client doit comporter au moins 4 caractères",
    'Customer phone is required': 'Le numéro de téléphone du client est requis',
    'Customer phone number must be at maximum 15 number': 'Le numéro de téléphone du client doit comporter au maximum 15 chiffres',
    'Customer phone number must be at least 8 number': 'Le numéro de téléphone du client doit comporter au moins 8 chiffres',
    'Customer id card number must be at least 8 characters': "Le numéro de carte d'identité du client doit comporter au moins 8 caractères",
    'Customer id card number is required': "Le numéro de carte d'identité du client est requis",
    'Customer passport number is required': 'Le numéro de passeport du client est requis',
    'Customer passport number must be at least 8 characters': 'Le numéro de passeport du client doit comporter au moins 8 caractères',
    'First Conductor first name is required': 'Le prénom du premier conducteur est requis',
    'First Conductor first name must be at least 3 characters': 'Le prénom du premier conducteur doit comporter au moins 3 caractères',
    'First Conductor last name must be at least 3 characters': 'Le nom de famille du premier conducteur doit comporter au moins 3 caractères',
    'First Conductor must be at least 18 years old': 'Le premier conducteur doit avoir au moins 18 ans',
    'First Conductor date of birth is required': 'La date de naissance du premier conducteur est requise',
    'First Conductor nationality is required': 'La nationalité du premier conducteur est requise',
    'First Conductor nationality must be at least 3 characters': 'La nationalité du premier conducteur doit comporter au moins 3 caractères',
    'First Conductor address is required': "L'adresse du premier conducteur est requise",
    'First Conductor address must be at least 4 characters': "L'adresse du premier conducteur doit comporter au moins 4 caractères",
    'First Conductor phone is required': 'Le numéro de téléphone du premier conducteur est requis',
    'First Conductor phone number must be at maximum 15 number': 'Le numéro de téléphone du premier conducteur doit comporter au maximum 15 chiffres',
    'First Conductor phone number must be at least 8 number': 'Le numéro de téléphone du premier conducteur doit comporter au moins 8 chiffres',
    'First Conductor id card number must be at least 8 characters': "Le numéro de carte d'identité du premier conducteur doit comporter au moins 8 caractères",
    'First Conductor id card number is required': "Le numéro de carte d'identité du premier conducteur est requis",
    'First Conductor passport number is required': 'Le numéro de passeport du premier conducteur est requis',
    'First Conductor passport number must be at least 8 characters': 'Le numéro de passeport du premier conducteur doit comporter au moins 8 caractères',
    'Second Conductor first name is required': 'Le prénom du deuxième conducteur est requis',
    'Second Conductor first name must be at least 3 characters': 'Le prénom du deuxième conducteur doit comporter au moins 3 caractères',
    'Second Conductor last name must be at least 3 characters': 'Le nom de famille du deuxième conducteur doit comporter au moins 3 caractères',
    'Second Conductor must be at least 18 years old': 'Le deuxième conducteur doit avoir au moins 18 ans',
    'Second Conductor date of birth is required': 'La date de naissance du deuxième conducteur est requise',
    'Second Conductor nationality is required': 'La nationalité du deuxième conducteur est requise',
    'Second Conductor nationality must be at least 3 characters': 'La nationalité du deuxième conducteur doit comporter au moins 3 caractères',
    'Second Conductor address is required': "L'adresse du deuxième conducteur est requise",
    'Second Conductor address must be at least 4 characters': "L'adresse du deuxième conducteur doit comporter au moins 4 caractères",
    'Second Conductor phone is required': 'Le numéro de téléphone du deuxième conducteur est requis',
    'Second Conductor phone number must be at maximum 15 number': 'Le numéro de téléphone du deuxième conducteur doit comporter au maximum 15 chiffres',
    'Second Conductor phone number must be at least 8 number': 'Le numéro de téléphone du deuxième conducteur doit comporter au moins 8 chiffres',
    'Second Conductor id card number must be at least 8 characters': "Le numéro de carte d'identité du deuxième conducteur doit comporter au moins 8 caractères",
    'Second Conductor id card number is required': "Le numéro de carte d'identité du deuxième conducteur est requis",
    'Second Conductor passport number is required': 'Le numéro de passeport du deuxième conducteur est requis',
    'Second Conductor passport number must be at least 8 characters': 'Le numéro de passeport du deuxième conducteur doit comporter au moins 8 caractères',
    'Please provide a driving license number': 'Veuillez fournir un numéro de permis de conduire',
    'Add second conductor': 'Ajouter un deuxième conducteur',
    'First conductor': 'Premier conducteur',
    'Second conductor': 'Deuxième conducteur',
    Birthday: 'Anniversaire',
    'Driver license information': 'Renseignements sur le permis de conduire',
    'Card delivered date': 'Date de livraison de la carte',
    'Identity card information': 'Renseignements sur la carte d’identité',
    'Phone number': 'Numéro de téléphone',
    'Phone number *': 'Numéro de téléphone *',
    // responses messages
    'State not found': 'État non trouvé',
    'the name of the season already exists': 'le nom de la saison existe déjà',
    'Season not found or deleted': 'Saison introuvable ou supprimée',
    'rentable not found': 'louable non trouvé',
    'Not authorized location !': 'Emplacement non autorisé !',
    'first conductor not found': 'premier conducteur introuvable',
    'An error occurred while updating the first conductor': 'Une erreur s’est produite lors de la mise à jour du premier conducteur',
    'reservation not found or not pending': 'réservation non trouvée ou non en cours',
    'An error occurred while updating the reservation': 'Une erreur est survenue lors de la mise à jour de la réservation',
    'payment method not found': 'mode de paiement introuvable',
    'Not authorized location!': 'Emplacement non autorisé!',
    'No rentable available for the dates provided, consider choosing another pricingGroup and explore more options!': 'Aucune location disponible pour les dates fournies, envisagez de choisir un autre groupe de prix et d’explorer d’autres options!',
    'This rentable is currently not available for the dates provided, consider choosing another rentable and explore more options!': 'Cette location n’est actuellement pas disponible pour les dates fournies, envisager de choisir une autre location et explorer d’autres options!',
    'Warning: additional driver is not allowed so it will be ignored in the reservation process': 'Attention : le conducteur supplémentaire n’est pas autorisé, il sera donc ignoré dans le processus de réservation',
    'Extra not found or not available': 'Extra introuvable ou non disponible',
    'return location is required': 'lieu de retour requis',
    'The reservation you are looking for could not be found, or it is not currently in a pending or in-progress state!': 'La réservation que vous recherchez n’a pas pu être trouvée, ou elle n’est pas actuellement en attente ou en cours!',
    'The reservation item you are looking for could not be found, or the reservation is with no rentable!': 'L’article de réservation que vous recherchez n’a pas pu être trouvé, ou la réservation est sans louage!',
    'The rentable in this reservation could not be found, please try to edit the reservation and select a new rentable!': 'La location dans cette réservation n’a pas pu être trouvé, s’il vous plaît essayer de modifier la réservation et sélectionner un nouveau louage!',
    'Given class is not compatible with rentable class': 'La classe donnée n’est pas compatible avec la classe louable',
    'Cannot resize this reservation because the rentable has no pricing group!': 'Ne peut pas redimensionner cette réservation parce que la location n’a pas de groupe de prix!',
    'You cannot drag and drop a reservation to a past date!': 'Vous ne pouvez pas glisser-déposer une réservation à une date antérieure!',
    'You can only extend or change the rentable of pending reservations!': 'Vous pouvez seulement prolonger ou modifier la location des réservations en attente!',
    'You cannot resize the reservation and change the rentable at the same time!': 'Vous ne pouvez pas redimensionner la réservation et modifier la location en même temps!',
    'The rentable you are looking to reserve could not be found!': 'La location que vous cherchez à réserver n’a pas pu être trouvé!',
    'This rentable has no pricing group, consider exploring other rentals!': 'Cette location n’a pas de groupe de prix, envisagez d’explorer d’autres locations!',
    'No rentable for reservation, must select a pricing group!': 'Pas de location pour la réservation, doit sélectionner un groupe de prix!',
    'Location not found !': 'Emplacement non trouvé !',
    'Reservation not found': 'Réservation introuvable',
    'Reservation Payment not found': 'Paiement de réservation introuvable',
    'there is no expenses': "Il n'y a pas de dépenses",
    'pricing group season created successfully': 'prix saison de groupe créé avec succès',
    'pricing group seasons deleted successfully': 'prix saison de groupe supprimé avec succès',
    'rentables added to pricing group successfully': 'rentabilité ajoutée au groupe de tarification avec succès',
    'rentables removed from pricing group successfully': 'rentables retirés du groupe de tarification avec succès',
    'Unable to logout!': 'Impossible de fermer la session!',
    'Branch created successfully': 'Branche créée avec succès',
    'Branch updated successfully': 'Branche mis à jour avec succès',
    'branch deleted successfully': 'Branche supprimée avec succès',
    'Classes created successfully': 'Classe créée avec succès',
    'Class updated successfully': 'Classe mis à jour avec succès',
    'classes has been deleted successfully !': 'les classes ont été supprimées avec succès !',
    'rentable retrieved successfully': 'location récupérée avec succès',
    'Rentable updated successfully': 'Location mise à jour avec succès',
    'rentable deleted successfully': 'Location supprimée avec succès',
    'rentables removed from branch successfully': 'Location supprimée avec succès',
    'rentables added to branch successfully': 'location ajoutée avec succès à la succursale',
    'Extra retrieved successfully': 'supplément retourné avec succès',
    'Extra updated successfully': 'Mise à jour supplémentaire réussie',
    'Extras deleted successfully': 'Extras supprimés avec succès',
    'pricing group season updated successfully': 'prix saison de groupe mis à jour avec succès',
    'Expense created successfully': 'Dépense créée avec succès',
    'expense found': 'dépense constatée',
    'expenses deleted successfully': 'dépenses supprimées avec succès',
    'expense deleted successfully': 'dépense supprimée avec succès',
    'expense edited successfully': 'dépense modifiée avec succès',
    'All customers returned successfully': 'Tous les clients sont retournés avec succès',
    'customers banned successfully': 'client(s) interdits avec succès',
    'customers deleted successfully': 'client(s)s supprimés avec succès',
    'Customer created successfully': 'Client créé avec succès',
    'customer edited successfully': 'client modifié avec succès',
    'reservation and customer created successfully': 'réservation et client créé avec succès',
    'rentable attribute value updated successfully': 'valeur de l’attribut louable mise à jour avec succès',
    'Second conductor deleted successfully': 'valeur de l’attribut louable mise à jour avec succès',
    'Reservation canceled successfuly': 'valeur de l’attribut louable mise à jour avec succès',
    'reservation updated': 'réservation mise à jour',
    'reservation updated successfully': 'réservation mise à jour avec succès',
    'cost transfer returned': 'transfert de coûts retourné',
    'seasons deleted successfully': 'saisons supprimées avec succès',
    'Season created successfully': 'Saison créée avec succès',
    'season deleted successfully': 'saison supprimée avec succès',
    'season edited successfully': 'saison éditée avec succès',
    'pricing groups deleted successfully': 'groupes de tarification supprimés avec succès',
    'pricing group created successfully': 'groupe de tarification créé avec succès',
    'pricing group updated successfully': 'Groupe de tarification mis à jour avec succès',
    'pricing group deleted successfully': 'groupe de tarification supprimé avec succès',
    'Extra created successfully': 'supplément créée avec succès',
    'rentable created successfully': 'location créée avec succès',
    'Select dates': 'Sélectionner les dates',
    'Rejected reservations': 'Réserves rejetées',
    Reservation: 'Réservation',
    reservation: 'Réservation',
    Reservations: 'Réservations',
    reservations: 'Réservations',
    "Rejected reservations' List": 'Liste des réservations rejetées',
    'Reservation Contract': 'Contrat de réservation',
    Country: 'Pays',
    Andorra: 'Andorre',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigéria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    'North Macedonia': 'Macédoine du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    'Palestine, State of': 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    Pitcairn: 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Réunion: 'Réunion',
    Romania: 'Roumanie',
    'Russian Federation': 'Russie',
    Rwanda: 'Rwanda',
    'New Caledonia': 'Nouvelle-Calédonie',
    'Saint Barthélemy': 'Saint-Barthélemy',
    Netherlands: 'Pays-Bas',
    Nauru: 'Nauru',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Île Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    'Micronesia, Federated States of': 'Micronésie',
    'Moldova, Republic of': 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar (Birmanie)',
    Namibia: 'Namibie',
    Nepal: 'Népal',
    'Saint Helena, Ascension and Tristan da Cunha': 'Sainte-Hélène, Ascension et Tristan da Cunha',
    'Saint Kitts and Nevis': 'Saint-Christophe-et-Niévès',
    'Saint Lucia': 'Sainte-Lucie',
    'Timor-Leste': 'Timor oriental',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    'Turks and Caicos Islands': 'Îles Turks et Caïques',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Arab Emirates': 'Émirats arabes unis',
    'United Kingdom of Great Britain and Northern Ireland': 'Royaume-Uni',
    'United States of America': "États-Unis d'Amérique",
    'United States Minor Outlying Islands': 'Îles mineures éloignées des États-Unis',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Venezuela, Bolivarian Republic of': 'Venezuela',
    'Viet Nam': 'Vietnam',
    'Virgin Islands, British': 'Îles Vierges britanniques',
    'Virgin Islands, U.S.': 'Îles Vierges des États-Unis',
    'Wallis and Futuna': 'Wallis-et-Futuna',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Thailand: 'Thaïlande',
    'Tanzania, United Republic of': 'Tanzanie',
    Tajikistan: 'Tadjikistan',
    'Taiwan, Province of China': 'Taïwan',
    'Saint Martin (French part)': 'Saint-Martin (partie française)',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    'Saudi Arabia': 'Arabie saoudite',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten (Dutch part)': 'Saint-Martin (partie néerlandaise)',
    Liberia: 'Libéria',
    Slovakia: 'Slovaquie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Africa': 'Afrique du Sud',
    'South Georgia and the South Sandwich Islands': 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    Spain: 'Espagne',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    'Syrian Arab Republic': 'République arabe syrienne',
    Slovenia: 'Slovénie',
    Zimbabwe: 'Zimbabwe',
    Lesotho: 'Lesotho',
    Latvia: 'Lettonie',
    'Brunei Darussalam': 'Brunéi Darussalam',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cabo Verde': 'Cap-Vert',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    'Cayman Islands': 'Îles Caïmans',
    'Central African Republic': 'République centrafricaine',
    Chad: 'Tchad',
    Chile: 'Chili',
    China: 'Chine',
    'Christmas Island': 'Île Christmas',
    'Cocos (Keeling) Islands': 'Îles Cocos (Keeling)',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    Congo: 'Congo',
    'Congo, the Democratic Republic of the': 'République démocratique du Congo',
    'Cook Islands': 'Îles Cook',
    'Costa Rica': 'Costa Rica',
    "Côte d'Ivoire": "Côte d'Ivoire",
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Cyprus: 'Chypre',
    Czechia: 'Tchéquie',
    'British Indian Ocean Territory': "Territoire britannique de l'océan Indien",
    Denmark: 'Danemark',
    Brazil: 'Brésil',
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algérie',
    'American Samoa': 'Samoa américaines',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belarus: 'Biélorussie',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    'Bolivia, Plurinational State of': 'Bolivie, État plurinational de',
    'Bonaire, Sint Eustatius and Saba': 'Bonaire, Saint-Eustache et Saba',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    'Bouvet Island': 'Île Bouvet',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'El Salvador': 'Salvador',
    'Equatorial Guinea': 'Guinée équatoriale',
    Eritrea: 'Érythrée',
    Estonia: 'Estonie',
    Eswatini: 'Eswatini',
    Ethiopia: 'Éthiopie',
    'Falkland Islands (Malvinas)': 'Îles Falkland (Malvinas)',
    'Faroe Islands': 'Îles Féroé',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    'French Guiana': 'Guyane française',
    'French Polynesia': 'Polynésie française',
    'French Southern Territories': 'Terres australes et antarctiques françaises',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Germany: 'Allemagne',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Greenland: 'Groenland',
    Grenada: 'Grenade',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haïti',
    'Heard Island and McDonald Islands': 'Îles Heard et McDonald',
    'Holy See': 'Saint-Siège',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    'Iran, Islamic Republic of': "Iran, République islamique d'",
    Iraq: 'Irak',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    "Korea, Democratic People's Republic of": 'Corée, République populaire démocratique de',
    'Korea, Republic of': 'Corée, République de',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    "Lao People's Democratic Republic": 'République démocratique populaire lao',
    'Åland Islands': 'Îles Åland',
    'Cancel Type *': 'Type d’annulation *',
    'Are you sure want to cancel the reservation?': 'Êtes-vous sûr de vouloir annuler la réservation?',
    Damaged: 'Endommagée',
    Undamaged: 'Non endommagée',
    agency: 'agence',
    'Create a new agency': 'Créer une nouvelle agence',
    'Create a New user': 'Créer un nouvel utilisateur',
    'New user': 'Nouvel utilisateur',
    'New agency': 'Nouvelle agence',
    'Create agency': 'Créer une agence',
    'Create user': 'Créer un utilisateur',
    'Edit agency': 'Modifier une agence',
    'Edit user': 'Modifier un utilisateur',
    'Identification Type Is Required': "Le type d'identification est requis",
    'Rentable created without pricing group': 'Location créée sans groupe de tarification',
    'Please provide id number': "Veuillez fournir un numéro d'identification",
    all: 'tout',
    deposit: 'dépôt',
    on_account: 'sur compte',
    penalty: 'pénalité',
    'Bank transfer': 'Virement bancaire',
    'Credit card': 'Carte de crédit',
    'already exist HQ branch': 'Il existe déjà une branche siège.',
    'firstConductor.firstName is required': 'Le prénom du premier conducteur est requis.',
    'customer.firstName is required': 'Le prénom du client est requis.',
    Active: 'Actif',
    active: 'Actif',
    users: 'Utilisateurs',
    'HQ Branch': 'Branche siège',
    'There already exists a headquarters branch. Do you want to set this branch as the headquarters?': 'Il existe déjà une branche siège. Voulez-vous définir cette branche comme siège ?',
    Yes: 'Oui',
    No: 'Non',
    Stamp: 'Timbre',
    Logo: 'Logo',
    ADMIN: 'ADMIN',
    OPERATOR: 'OPÉRATEUR',
    Email: 'Email',
    'Last Name': 'Prénom',
    'First Name': 'Nom',
    'Allowed *.jpeg, *.jpg, *.png, *.gif': 'Autorisé *.jpeg, *.jpg, *.png, *.gif',
    'Start Working Date': "Date de début d'activité",
    'max size of': 'taille maximale de ',
    Agencies: 'Agences',
    Agency: 'Agence',
    'Search by Agency Name': "Recherche par Nom d'Agence",
    'Search by Users Name , Email ,Phone number': "Recherche par Nom d'utilisateur, Email , Télephone",
    Users: 'Utilisateurs',
    User: 'Utilisateur',
    'Full Name': 'Nom complet',
    'FirstName is required': 'Le prénom est requis',
    'LastName is required': 'Le nom est requis',
    'Email is required': "L'adresse e-mail est requise",
    'Password is required': 'Le mot de passe est requis',
    'Email address is required': "L'adresse e-mail est requise",
    'Phone number is required': 'Le numéro de téléphone est requis',
    'Start Working Date is required': 'La date de début de travail est requise',
    'Notes is required': 'Des notes sont requises',
    'Agency name is required': "Le nom de l'agence est requis",
    'Stamp is required': 'Le tampon est requis',
    'Role is required': 'Le rôle est requis',
    'Damage Report': 'Rapport de dommages',
    'Please indicate the rentable state, the decision in case the damage is nonfunctional, and the description if necessary.': 'Veuillez indiquer l’état de location, la décision dans le cas où le dommage est non fonctionnel, et la description si nécessaire.',
    'Rentable state *': 'Ètat de location *',
    'Decision *': 'Décision *',
    Report: 'Rapport',
    Functional: 'Fonctionnel',
    NonFunctional: 'Non fonctionnel',
    'Continue with the current rentable': 'Continuer avec la location actuelle',
    'Change rentable': 'Changer de location',
    'Return rentable': 'Retour de location',
    'Payment Check': 'Chèque de paiement',
    'There was an issue sending the email. Please resend it from your account dashboard, or contact support for assistance.': " Il y a eu un problème lors de l'envoi de l'e-mail. Veuillez le renvoyer depuis le tableau de bord de votre compte ou contacter l'assistance pour obtenir de l'aide.",
    'There is a difference of': 'Il y a une différence de',
    'Do you want to give back the amount or a portion?': 'Voulez-vous rendre la quantité ou une portion?',
    'The difference is': 'La différence est',
    'customer needs to pay': 'Le client doit payer',
    'Give back the amount or a portion *': 'Remettre le montant ou une portion *',
    'Amount *': 'Montant *',
    'agency created successfully': 'Agence créée avec succès.',
    'agency updated successfully': 'Agence mise à jour avec succès.',
    'user agency created successfully': "L'utilisateur de l'agence créée avec succès",
    'user agency updated successfully': "L'utilisateur de l'agence mise à jour avec succès",
    'agencies deleted successfully': 'Agences supprimées avec succès',
    'users of agencies deleted successfully': "Utilisateurs d'agences supprimés avec succès",
    'Would you like to continue?': 'Voulez-vous continuer?',
    'Update created Invoice.': 'Mettre à jour la facture créée.',
    'Amount must be greater than or equal to the required amount': 'Le montant doit être supérieur ou égal au montant requis',
    "However, you can't refund it as the invoice is linked.": 'Cependant, vous ne pouvez pas le rembourser car la facture est liée.',
    'Linked invoice. Create a new one?': 'Facture liée. En créer une nouvelle ?',
    'Linked invoice. Want to create a new one with the excess over the needed amount?': 'Facture liée. Vous souhaitez en créer une nouvelle avec l’excédent sur le montant requis ?',
    'Create new Invoice.': 'Créez une nouvelle facture.',
    agencies: 'agences',
    'Agency Name': "Nom de l'agence ",
    'overrideHQ is not allowed': 'Outrepasser branche siège n’est pas autorisé',
    'Identity Type': 'Type d’identité',
    'Identity Number': 'Numéro d’identité',
    'Search by Name, Nationality, or Phone...': 'Recherche par nom, nationalité ou téléphone...',
    cin: 'Cin',
    driving_license: 'Permis de conduire',
    passport: 'Passeport',
    residence_permit: 'Permis de séjour',
    Paid: 'Payé',
    Difference: 'Différence',
    'The customer has driven more kilometers than allowed for the day, so he needs to pay a fine of': 'Le client a dépassé le nombre maximum de kilomètres quotidiens autorisés, il doit donc payer une pénalité de',
    'Penalty Check': 'Vérification de pénalité',
    'Pay penalty *': 'Payer la pénalité *',
    // `km value cannot be less than the initial value => ${value}`:
    //   `La valeur km ne peut être inférieure à la valeur initiale => ${value}`,
    'penalty return checked successfuly': 'Retour de pénalité vérifié avec succès',
    'report payment checked successfuly': 'Rapport de paiement vérifié avec succès',
    'User Details': "Détails de l'utilisateur",
    operator: 'Operateur',
    'Is Active': 'Actif',
    "you can't delete agency has a least one rentable": 'Vous ne pouvez pas supprimer une agence ayant au moins une location disponible',
    'Send to email': 'Envoyer par e-mail.',
    'Please enter the customer email address:': "Veuillez entrer l'adresse e-mail du client:",
    'Email sent': 'e-mail envoyé',
    'Error sending email': "Erreur lors de l'envoi de l'e-mail",
    'user has no agency': "L'utilisateur n'a pas d'agence.",
    'Description is a required field': 'Description est un champ obligatoire',
    'agency user not found': 'utilisateur de l’agence introuvable',
    "you can't use existing email or phone number": 'vous ne pouvez pas utiliser le courriel ou le numéro de téléphone existant',
    'A driving_license identification is required for the driver': '  Une identification driving_license est requise pour le conducteur',
    'The date must be greater than or equal to the current date.': 'La date doit être supérieure ou égale à la date du jour.',
    'rentable not available at the given date, please choose another date or explore other other rentals!': 'Location non disponible à la date indiquée, veuillez choisir une autre date ou explorer d’autres locations!',
    'No more expensive pricing group, consider choose manually': 'Pas de groupe de prix plus cher, envisager de choisir manuellement',
    'Agency Details': 'Détails de l’agence',
    'Estimated Amount': 'Montant estimé',
    'email already used': 'email déjà utilisé',
    'Passwords must match': 'Les mots de passe doivent correspondre.',
    'Password Confirm': 'Confirmation du mot de passe',
    'Password Confirm is required': 'Confirmation du mot de passe requise',
    'Confirm account': 'Confirmer le compte',
    'Password has been reset': 'Mot de passe réinitialisé',
    'Send confirmation account mail': 'Envoyer le courrier de confirmation de compte',
    Send: 'Envoyer',
    'Email must be a valid email': "L'e-mail doit être valide",
    'Password reset link has been sent to your email.': 'Le lien de réinitialisation du mot de passe a été envoyé à votre e-mail.',
    'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.': 'Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
    Rejected: 'Rejetée',
    'No user found with this email': 'Aucun utilisateur trouvé avec cet email',
    'User does not have any associated agencies': 'L’utilisateur n’a pas d’agences associées',
    'Invalid or expired reset token': 'Jeton de réinitialisation non valide ou expiré',
    'Not Found': 'Non Trouvé',
    'Send Verification Link': 'Envoyer le lien de vérification',
    "Enter your email address to reset your password or activate your account. We'll send you a verification link to your email.": 'Entrez votre adresse e-mail pour réinitialiser votre mot de passe ou activer votre compte. Nous vous enverrons un lien de vérification vers votre e-mail.',
    'Email Verification': 'Vérification par courriel',
    'Email Address Verification': 'Vérification de l’adresse courriel',
    'Password Reset': 'Réinitialisation du mot de passe',
    'Forgot password?': 'Mot de passe oublié?',
    'Return to sign in': 'Retourner à se connecter',
    "can't delete superadmin": 'Impossible de supprimer superadm',
    "Reservation not paid, can't process pickup!": 'Réservation non payée, impossible de traiter le ramassage!',
    'Manual Price Description': 'Description du prix manuelle',
    'Manual Price Description is required': 'La description du prix manuelle est requise',
    'manualPriceDescription length must be at least 3 characters long': 'La longueur de la description doit être d’au moins 5 caractères',
    'Nationality is required': 'La nationalité est requise',
    'km is required': 'km est requis',
    'fuel is required': 'le carburant est nécessaire',
    'first Conductor last name is required': 'le nom de famille du premier conducteur est requis',
    'first Conductor first name is required': 'le prénom du conducteur est requis',
    'first Conductor date of birth is required': 'la date de naissance du premier conducteur est requise',
    'first Conductor phone is required': 'premier téléphone conducteur requis',
    'first Conductor address is required': 'la première adresse du conducteur est requise',
    'Permission Denied': 'Permission refusée',
    'You do not have permission to access this page': 'Vous n’êtes pas autorisé à accéder à cette page',
    'logo is a required field': 'logo est un champ obligatoire',
    'Transfer Base Cost?': 'Coût de base du transfert?',
    'Transfer Km Cost?': 'Coût du transfert en km?',
    'Deposit is required?': 'Dépôt requis?',
    'Deny Not Paid Reservation': 'Refuser la réservation non payée',
    'Required Manual Price': 'Prix manuel requis',
    'Young vehicle month limit?': 'Limite pour les jeunes véhicules?',
    'How much percentage surcharge the young vehicle payment?': 'Quel pourcentage de supplément le jeune véhicule paiement?',
    'How much surcharge the young vehicle payment?': 'Combien de supplément le jeune paiement de véhicule?',
    "Reservation not paid, can't process pickup without providing the reason!": 'Réservation non payée, ne peut pas traiter le ramassage sans fournir la raison!',
    // nationalities
    Afghan: 'Afghan',
    'Åland Island': 'Îles Åland',
    Albanian: 'Albanais',
    Algerian: 'Algérien',
    'American Samoan': 'Samoan Américain',
    Andorran: 'Andorran',
    Angolan: 'Angolais',
    Anguillan: 'Anguillais',
    Antarctic: 'Antarctique',
    'Antiguan or Barbudan': 'Antiguais ou Barbudien',
    Argentine: 'Argentin',
    Armenian: 'Arménien',
    Aruban: 'Arubais',
    Australian: 'Australien',
    Austrian: 'Autrichien',
    'Azerbaijani, Azeri': 'Azerbaïdjanais, Azéri',
    Bahamian: 'Bahaméen',
    Bahraini: 'Bahreïnien',
    Bangladeshi: 'Bangladais',
    Barbadian: 'Barbadien',
    Belarusian: 'Biélorusse',
    Belgian: 'Belge',
    Belizean: 'Bélizien',
    'Beninese, Beninois': 'Béninois',
    'Bermudian, Bermudan': 'Bermudien',
    Bhutanese: 'Bhoutanais',
    Bolivian: 'Bolivien',
    Bonaire: 'Bonaire',
    'Bosnian or Herzegovinian': 'Bosniaque ou Herzégovinien',
    'Motswana, Botswanan': 'Botswanais',
    Brazilian: 'Brésilien',
    BIOT: "Territoire britannique de l'océan Indien",
    Bruneian: 'Brunéien',
    Bulgarian: 'Bulgare',
    Burkinabé: 'Burkinabé',
    Burundian: 'Burundais',
    'Cabo Verdean': 'Cap-verdien',
    Cambodian: 'Cambodgien',
    Cameroonian: 'Camerounais',
    Canadian: 'Canadien',
    Caymanian: 'Caïmanais',
    'Central African': 'Centrafricain',
    Chadian: 'Tchadien',
    Chilean: 'Chilien',
    Chinese: 'Chinois',
    'Cocos Island': 'Îles Cocos',
    Colombian: 'Colombien',
    'Comoran, Comorian': 'Comorien',
    Congolese: 'Congolais',
    'Cook Island': 'Îles Cook',
    'Costa Rican': 'Costaricain',
    Ivorian: 'Ivoirien',
    Croatian: 'Croate',
    Cuban: 'Cubain',
    Curaçaoan: 'Curaçaoan',
    Cypriot: 'Chypriote',
    Czech: 'Tchèque',
    Danish: 'Danois',
    Djiboutian: 'Djiboutien',
    Dominican: 'Dominicain',
    Ecuadorian: 'Équatorien',
    Egyptian: 'Égyptien',
    Salvadoran: 'Salvadorien',
    'Equatorial Guinean, Equatoguinean': 'Équatoguinéen',
    Eritrean: 'Érythréen',
    Estonian: 'Estonien',
    Ethiopian: 'Éthiopien',
    'Falkland Island': 'Îles Malouines',
    Faroese: 'Féroïen',
    Fijian: 'Fidjien',
    Finnish: 'Finlandais',
    French: 'Français',
    'French Guianese': 'Guyanais',
    'French Polynesian': 'Polynésien français',
    Gabonese: 'Gabonais',
    Gambian: 'Gambien',
    Georgian: 'Géorgien',
    German: 'Allemand',
    Ghanaian: 'Ghanéen',
    'Greek, Hellenic': 'Grec, Hellénique',
    Greenlandic: 'Groenlandais',
    Grenadian: 'Grenadien',
    'Guamanian, Guambat': 'Guamanien',
    Guatemalan: 'Guatémaltèque',
    'Channel Island': 'Îles Anglo-Normandes',
    Guinean: 'Guinéen',
    'Bissau-Guinean': 'Bissau-Guinéen',
    Guyanese: 'Guyanais',
    Haitian: 'Haïtien',
    'Heard Island or McDonald Islands': 'Îles Heard et McDonald',
    Vatican: 'Vatican',
    Honduran: 'Hondurien',
    'Hong Kong, Hong Kongese': 'Hongkongais',
    'Hungarian, Magyar': 'Hongrois',
    Icelandic: 'Islandais',
    Indian: 'Indien',
    Indonesian: 'Indonésien',
    'Iranian, Persian': 'Iranien, Persan',
    Iraqi: 'Irakien',
    Irish: 'Irlandais',
    Manx: 'Manx',
    Israeli: 'Israélien',
    Italian: 'Italien',
    Jamaican: 'Jamaïcain',
    Japanese: 'Japonais',
    Jordanian: 'Jordanien',
    'Kazakhstani, Kazakh': 'Kazakh',
    Kenyan: 'Kényan',
    'I-Kiribati': 'Kiribatien',
    'North Korean': 'Coréen du Nord',
    'South Korean': 'Coréen du Sud',
    Kuwaiti: 'Koweïtien',
    'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz': 'Kirghize',
    'Lao, Laotian': 'Laotien',
    Latvian: 'Letton',
    Lebanese: 'Libanais',
    Basotho: 'Basotho',
    Liberian: 'Libérien',
    Libyan: 'Libyen',
    Lithuanian: 'Lituanien',
    'Luxembourg, Luxembourgish': 'Luxembourgeois',
    'Macanese, Chinese': 'Macanais, Chinois',
    Macedonian: 'Macédonien',
    Malagasy: 'Malgache',
    Malawian: 'Malawien',
    Malaysian: 'Malaisien',
    Maldivian: 'Maldivien',
    'Malian, Malinese': 'Malien',
    Maltese: 'Maltais',
    Marshallese: 'Marshallais',
    'Martiniquais, Martinican': 'Martiniquais',
    Mauritanian: 'Mauritanien',
    Mauritian: 'Mauricien',
    Mahoran: 'Mahorais',
    Mexican: 'Mexicain',
    Micronesian: 'Micronésien',
    Moldovan: 'Moldave',
    'Monégasque, Monacan': 'Monégasque',
    Mongolian: 'Mongol',
    Montenegrin: 'Monténégrin',
    Montserratian: 'Montserratien',
    Moroccan: 'Marocain',
    Mozambican: 'Mozambicain',
    Burmese: 'Birman',
    Namibian: 'Namibien',
    Nauruan: 'Nauruan',
    'Nepali, Nepalese': 'Népalais',
    'Dutch, Netherlandic': 'Néerlandais',
    'New Caledonian': 'Néo-Calédonien',
    'New Zealand, NZ': 'Néo-Zélandais',
    Nicaraguan: 'Nicaraguayen',
    Nigerien: 'Nigérien',
    Nigerian: 'Nigérian',
    Niuean: 'Niuéen',
    'Northern Marianan': 'Marianais du Nord',
    Norwegian: 'Norvégien',
    Omani: 'Omanais',
    Pakistani: 'Pakistanais',
    Palauan: 'Paluan',
    Palestinian: 'Palestinien',
    Panamanian: 'Panaméen',
    'Papua New Guinean, Papuan': 'Papouasien',
    Paraguayan: 'Paraguayen',
    Peruvian: 'Péruvien',
    'Philippine, Filipino': 'Philippin',
    'Pitcairn Island': 'Îles Pitcairn',
    Polish: 'Polonais',
    Portuguese: 'Portugais',
    'Puerto Rican': 'Portoricain',
    Qatari: 'Qatari',
    'Réunionese, Réunionnais': 'Réunionnais',
    Romanian: 'Roumain',
    Russian: 'Russe',
    Rwandan: 'Rwandais',
    Barthélemois: 'Barthélemois',
    'Saint Helenian': 'Saint-Hélénien',
    'Kittitian or Nevisian': 'Kittitien ou Névisien',
    'Saint Lucian': 'Saint-Lucien',
    'Saint-Martinoise': 'Saint-Martinoise',
    'Saint-Pierrais or Miquelonnais': 'Saint-Pierrais ou Miquelonnais',
    'Saint Vincentian, Vincentian': 'Saint-Vincentais',
    Samoan: 'Samoan',
    Sammarinese: 'Saint-Marinais',
    'São Toméan': 'Santoméen',
    'Saudi, Saudi Arabian': 'Saoudien',
    Senegalese: 'Sénégalais',
    Serbian: 'Serbe',
    Seychellois: 'Seychellois',
    'Sierra Leonean': 'Sierra-léonais',
    Singaporean: 'Singapourien',
    'Sint Maarten': 'Sint Maarten',
    Slovak: 'Slovaque',
    'Slovenian, Slovene': 'Slovène',
    'Solomon Island': 'Îles Salomon',
    'Somali, Somalian': 'Somalien',
    'South African': 'Sud-Africain',
    'South Georgia or South Sandwich Islands': 'Géorgie du Sud et îles Sandwich du Sud',
    'South Sudanese': 'Soudanais du Sud',
    Spanish: 'Espagnol',
    'Sri Lankan': 'Sri Lankais',
    Sudanese: 'Soudanais',
    Surinamese: 'Surinamien',
    Svalbard: 'Svalbard',
    Swazi: 'Swazi',
    Swedish: 'Suédois',
    Swiss: 'Suisse',
    Syrian: 'Syrien',
    'Chinese, Taiwanese': 'Chinois, Taïwanais',
    Tajikistani: 'Tadjik',
    Tanzanian: 'Tanzanien',
    Thai: 'Thaïlandais',
    Timorese: 'Timorais',
    Togolese: 'Togolais',
    Tokelauan: 'Tokélaouan',
    Tongan: 'Tongien',
    'Trinidadian or Tobagonian': 'Trinidadien ou Tobagonien',
    Turkish: 'Turc',
    Turkmen: 'Turkmène',
    'Turks and Caicos Island': 'Îles Turques-et-Caïques',
    Tuvaluan: 'Tuvaluan',
    Ugandan: 'Ougandais',
    Ukrainian: 'Ukrainien',
    'Emirati, Emirian, Emiri': 'Émirien',
    'British, UK': 'Britannique, Royaume-Uni',
    American: 'Américain',
    Uruguayan: 'Uruguayen',
    'Uzbekistani, Uzbek': 'Ouzbek',
    'Ni-Vanuatu, Vanuatuan': 'Ni-Vanuatu',
    Venezuelan: 'Vénézuélien',
    Vietnamese: 'Vietnamien',
    'British Virgin Island': 'Îles Vierges britanniques',
    'U.S. Virgin Island': 'Îles Vierges américaines',
    'Wallis and Futuna, Wallisian or Futunan': 'Wallis et Futuna',
    'Sahrawi, Sahrawian, Sahraouian': 'Sahraoui',
    Yemeni: 'Yéménite',
    Zambian: 'Zambien',
    Zimbabwean: 'Zimbabwéen',
    view: 'Vue',
    delete: 'Supprimer',
    archive: 'Archiver',
    unarchive: 'Désarchive',
    import: 'Importer',
    export: 'Exporter',
    location: 'Emplacement',
    pricingGroupSeason: 'Tarification de la saison de groupe',
    pricingGroup: 'Groupe de tarification',
    // rentable: 'location',
    reservationPayment: 'Paiement de la réservation',
    expenses: 'Dépenses',
    payments: 'Paiements',
    rentableExtra: 'Supplément du location',
    Models: 'Modèles',
    Model: 'Modèle',
    Groups: 'Groupes',
    Group: 'Groupe',
    role: 'Rôle',
    Role: 'Rôle',
    'An 8-digit confirmation code has been sent to your email. Please input the code to reset your password or activate your account. Set and confirm your new password, and you will be guided to the sign-in page.': 'Un code de confirmation à 8 chiffres a été envoyé à votre courriel. Veuillez entrer le code pour réinitialiser votre mot de passe ou activer votre compte. Définissez et confirmez votre nouveau mot de passe, et vous serez guidé vers la page de connexion.',
    Reset: 'Réinitialiser',
    'Resend code': 'Renvoyer le code',
    'Don’t have a code?': 'Vous n’avez pas de code ?',
    'permission groups': "groupes d'autorisations",
    'Invalid file type': 'type de fichier invalide',
    'bank accounts': 'compte bancaires',
    Invoices: 'Factures',
    Invoice: 'Facture',
    invoices: 'Factures',
    Admin: 'administrateur',
    'New season': 'Nouvelle saison',
    'Search...': 'Recherche...',
    'Create a new season': 'Créer une nouvelle saison',
    Operator: 'Operateur',
    'New invoice': 'Nouvelle facture',
    'Create A New Invoice': 'Créer une nouvelle facture',
    Finance: 'Finance',
    'Invoice Items': 'Éléments de facture',
    Change: 'Changer',
    Select: 'Sélectionner',
    'TVA Percentage': 'Pourcentage TVA',
    TVA: 'TVA',
    'Add Invoice Item': 'Ajouter un article de facture',
    Qty: 'Quantité',
    'Price / day': 'Prix / jour',
    'Invoice Number': 'Numéro de facture',
    'Invoice Details': 'Détails de la facture',
    PAID: 'Payé',
    UNPAID: 'Non payé',
    UnPaid: 'Non payé',
    Linked: 'Lié',
    UnLinked: 'non lié',
    'Invoice to': 'Facturer à',
    'Created Date': 'Date de création',
    'Tax included': 'Taxe incluse',
    Subtotal: 'Sous-total',
    'excel file is required': 'fichier excel requis',
    Create: 'Créer',
    'Rows per page': 'lignes par page',
    'create invoice': 'créer une facture',
    'has an invoice': 'a une facture',
    Affiliations: 'Affiliations',
    'New Affiliation': 'Nouvelle Affiliation',
    'Create A New Affiliation': 'Créer une Nouvelle Affiliation',
    'Agency is required': "L'agence est requise",
    'Coins must be an integer': 'Les pièces doivent être un entier',
    'Coins are required': 'Les pièces sont requises',
    'Coins can have a maximum of 9 digits': 'Les pièces peuvent avoir un maximum de 9 chiffres',
    'Coin price is required': 'Le prix de la pièce est requis',
    'Coin price must have no more than 10 digits in total': 'Le prix de la pièce ne doit pas dépasser 10 chiffres au total',
    'Invalid Date': 'Date invalide',
    Coins: 'Pièces',
    'Coin Price': 'Prix de la pièce',
    'The default value is set to the end of the current month': 'La valeur par défaut est fixée à la fin du mois en cours',
    'Allow Extra Period': 'Autoriser une période supplémentaire',
    'Affiliation details': "Détails de l'affiliation",
    'Coin Total Price': 'Prix total des pièces',
    Expired: 'Expiré',
    'Edit Affiliation': "Modifier l'affiliation",
    'End At': 'Fin à',
    'Start At': 'Début à',
    'Extra Period': 'Période supplémentaire',
    'new affiliation': 'nouvelle affiliation',
    'Search by agency name...': "Rechercher par nom d'agence...",
    Annually: 'Annuel',
    annually: 'Annuel',
};
export default fr;
